import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "grid",
  id: "elementGrid"
}
const _hoisted_2 = { class: "flex align-center g10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeImage = _resolveComponent("SeImage")!
  const _component_WideCard = _resolveComponent("WideCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elements, (element) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: element.id
      }, [
        (element.getActive() || _ctx.unactives)
          ? (_openBlock(), _createBlock(_component_WideCard, {
              key: 0,
              active: element.getActive(),
              icon: 
          element.getIllustrationUrl() == null ||
          element.getIllustrationUrl() == ''
            ? '/ico/equipement.svg'
            : element.getIllustrationUrlWithWatermark(_ctx.database)
        ,
              title: element.getName(),
              click: () => _ctx.onElementClick(element),
              obj: _ctx.storeDatabase ? _ctx.store : _ctx.database,
              obj2: element,
              redirectPage: _ctx.storeDatabase ? 'storeElement' : 'databaseElement'
            }, {
              subtitle: _withCtx(() => [
                _createTextVNode(_toDisplayString(element.getActive()
              ? _ctx.Lang.getI18N("active")
              : _ctx.Lang.getI18N("inactive")), 1)
              ]),
              footer: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  (element.getTemperaturesModuleActive())
                    ? (_openBlock(), _createBlock(_component_SeImage, {
                        key: 0,
                        src: "/ico/temperature.svg"
                      }))
                    : _createCommentVNode("", true),
                  (element.getLabelsModuleActive())
                    ? (_openBlock(), _createBlock(_component_SeImage, {
                        key: 1,
                        src: "/ico/printer.svg"
                      }))
                    : _createCommentVNode("", true),
                  (element.getTraceabilityModuleActive())
                    ? (_openBlock(), _createBlock(_component_SeImage, {
                        key: 2,
                        src: "/ico/camera.svg"
                      }))
                    : _createCommentVNode("", true),
                  (element.getTemperatureControlModuleActive())
                    ? (_openBlock(), _createBlock(_component_SeImage, {
                        key: 3,
                        src: "/ico/temperature_control.svg"
                      }))
                    : _createCommentVNode("", true),
                  (element.getOilsModuleActive())
                    ? (_openBlock(), _createBlock(_component_SeImage, {
                        key: 4,
                        src: "/ico/oils.svg"
                      }))
                    : _createCommentVNode("", true),
                  (element.isLocal())
                    ? (_openBlock(), _createBlock(_component_SeImage, {
                        key: 5,
                        maxWidth: 20,
                        maxHeight: 20,
                        src: "ico/store.svg"
                      }))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 2
            }, 1032, ["active", "icon", "title", "click", "obj", "obj2", "redirectPage"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}