
import * as Lang from "@/i18n/lang";
import { getCommunicationState } from "@/pinia/communications/CommunicationState";
import { defineComponent } from "vue";
import WideCard from "@/components/global/WideCard.vue";
import * as Snackbars from "@/utils/Snackbars";
import * as DOMUtils from "@/utils/DOMUtils";
import { getAttachmentManager } from "@/firebase/AttachmentManager";
import Attachment from "@/model/Filesystem/Attachment";
import * as UploadManager from "@/model/Uploads/UploadManager";
import CommunicationTargetStore from "@/components/communication/CommunicationTargetStore.vue";
import CommunicationQuiz from "@/components/communication/CommunicationQuiz.vue";
import * as Modals from "@/utils/Modals";
import { getCommunicationsState } from "@/pinia/communications/CommunicationsState";
import CommunicationStatus from "@/components/communication/CommunicationStatus.vue";
import CommunicationTargetRole from "@/components/communication/CommunicationTargetRole.vue";
import { nextTick } from "vue";

export default defineComponent({
  async setup() {
    let state = getCommunicationState();

    return {
      state: state,
    };
  },
  data() {
    return {
      Lang: Lang,
    };
  },

  mounted() {
    var refs = <any>this.$refs;
    refs.calendar.setStart(this.state.communication!.start);
    refs.calendar.setEnd(this.state.communication!.end);

    if (
      this.state.communication!.quiz != null &&
      this.state.communication!.quiz.questions.length > 0
    ) {
      this.state.selectedQuestion = this.state.communication!.quiz.questions[0];
    }
  },

  methods: {
    downloadAttachment() {
      this.state.communication?.attachment?.download();
    },
    openRoleFilterModal() {
      var refs = <any>this.$refs;
      refs.roleFilterModal.open();

      refs.targetRole.displayFilters();
    },
    closeRoleFilterModal() {
      var refs = <any>this.$refs;
      refs.targetRole.applyFilters();
      refs.roleFilterModal.close();
    },
    openStoreFilterModal() {
      var refs = <any>this.$refs;
      refs.storeFilterModal.open();

      refs.targetStore.displayFilters();
    },
    closeStoreFilterModal() {
      var refs = <any>this.$refs;
      refs.targetStore.applyFilters();
      refs.storeFilterModal.close();
    },
    uploadAttachment() {
      DOMUtils.attachementFileDialog(this.onAttachmentUploaded);
    },
    async onAttachmentUploaded(e: any) {
      let file = e.target.files[0];

      var path =
        "/databases/" +
        this.state.database?.ref.id! +
        "/communications/" +
        this.state.communication?.ref.id!;

      var strategy = await UploadManager.createStrategy(file, path, true);

      UploadManager.addFileToUpload(strategy);

      this.state.communication!.attachment = strategy.generateAttachment()!;
    },

    async update() {
      let refs = <any>this.$refs;

      if (refs.modifyBtn.isLoading()) {
        return;
      }

      refs.modifyBtn.toggleLoading(true);

      if (this.state.communication?.attachment == null) {
        refs.modifyBtn.toggleLoading(false);
        Modals.open("Please select an attachement for this communication.");
        return;
      }

      if (this.state.communication.quiz != null) {
        for (let question of this.state.communication.quiz!.questions) {
          if (
            question.answers.length == 0 ||
            !question.answers.some((x) => x.correct)
          ) {
            refs.modifyBtn.toggleLoading(false);
            Modals.open(Lang.getI18N("communication.no_valid_answer"));
            return;
          }
        }
      }

      this.state.communication!.start = refs.calendar.getStart();

      this.state.communication!.start.setHours(0);
      this.state.communication!.start.setMinutes(0);
      this.state.communication!.start.setSeconds(0);

      this.state.communication!.end = refs.calendar.getEnd();

      this.state.communication!.end.setHours(23);
      this.state.communication!.end.setMinutes(59);
      this.state.communication!.end.setSeconds(59);

      await UploadManager.applyUploads();

      await this.state.communication.attachment.generateUrl();

      await this.state.communication?.set();

      if (this.state.creation) {
        getCommunicationsState().communications.push(this.state.communication);
      }

      refs.modifyBtn.toggleLoading(false);

      Snackbars.display(Lang.getI18N("saved-successfully"));
    },
  },
  components: {
    WideCard,
    CommunicationTargetStore,
    CommunicationQuiz,
    CommunicationStatus,
    CommunicationTargetRole,
  },
});
