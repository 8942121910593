
import * as Storage from "@/firebase/Storage";
import * as Snackbars from "@/utils/Snackbars";
import * as Modals from "@/utils/Modals";
import * as MailManager from "../firebase/MailManager";
import * as Lang from "@/i18n/lang";
import SeInput from "../components/global/SeInput.vue";
import ToggleSwitch from "../components/global/ToggleSwitch.vue";
import SeButton from "../components/global/SeButton.vue";
import User from "../model/User";
import dateFormat from "dateformat";
import Database from "@/model/Database";
import ProbeList from "../components/probes/ProbeList.vue";
import Invitation, { InvitationType } from "@/model/Invitation";
import { getState } from "../pinia/NavigationState";
import { getStoreState } from "@/pinia/stores/StoreState";
import { getState as getAppState } from "@/pinia/AppState";
import { getStoreDatabaseState } from "@/pinia/databases/StoreDatabaseState";
import { getAccountState } from "@/pinia/accounts/AccountState";
import { defineComponent } from "vue";
import { Role } from "@/model/User";
import { ModuleEnum } from "@/model/ModuleEnum";
import { getUserManager } from "../firebase/UserManager";
import { getStoreManager } from "../firebase/StoreManager";
import { getDatabaseManager } from "../firebase/DatabaseManager";
import { collection, getDoc } from "firebase/firestore";
import * as Firebase from "@/firebase/Firebase";
import { doc, DocumentReference } from "firebase/firestore";

import * as SecureatServerApi from "@/utils/SecureatServerApi";
import ReadonlyField from "@/components/global/ReadonlyField.vue";
import { KooveaSticker } from "@/model/Koovea/KooveaSticker";
import ActivationHistory from "@/model/ActivationHistory";
import SecureatIllustration from "@/model/utils/SecureatIllustration";

export default defineComponent({
  async mounted() {},
  async setup() {
    let appState = getAppState();

    let state = getStoreState();

    await state.setup();

    return {
      toggleRight: appState.customStoreId == null,
      role: getAppState().userData?.role,
      state: state,
    };
  },
  methods: {
    async removeOwner(user: User) {
      user.stores = user.stores.filter(
        (obj) => obj.id !== this.state.selectedStore!.ref?.id
      );

      let storeManager = getStoreManager();

      let stores = await storeManager.getStoresOfUser(user);

      for (let distributor of user.store_distributors) {
        if (!stores.some((x) => x.distributor.id == distributor.id)) {
          user.store_distributors.filter((x) => x == distributor);
        }
      }

      this.state.owners = this.state.owners!.filter((x) => x != user);
      await user.set();
    },

    async onIllustrationChange(event: ProgressEvent, file: File) {
      await Storage.uploadStoreIllustration(this.state.selectedStore!, file);

      this.state.selectedStore!.illustration_url = new SecureatIllustration(
        await Storage.getStoreIllustrationUrl(this.state.selectedStore!)
      );

      await this.state.selectedStore!.set();
    },

    async selectDatabase() {
      let refs = <any>this.$refs;

      let database = this.state.selectedDatabase;

      if (database !== null && database.distributor != null) {
        this.state.selectedStore!.database = database.ref!;
        this.state.selectedStore!.distributor = database.distributor!;
        if (
          !database.stores.some(
            (x) => x.id == this.state.selectedStore!.ref?.id
          )
        ) {
          database.stores.push(this.state.selectedStore!.ref!);
          await database.set();
        }
      } else {
        this.dbError = Lang.getI18N("invalid-database");
        return;
      }

      this.state.currentDatabase = database;

      await this.state.selectedStore!.set();

      refs.databasesModal.close();
    },
    async addOwner() {
      let refs = <any>this.$refs;

      let inputEmail = refs.ownerName.getValue();

      if (inputEmail.trim() == "") {
        return;
      }

      var result = await SecureatServerApi.addStoreOwner(
        inputEmail,
        this.state.selectedStore!.ref!.id
      );

      if (result.status == "not-elligible") {
        Snackbars.display(
          "Cet utilisateur n'est pas elligible a l'établissement."
        );

        return;
      }

      if (result.status == "invited") {
        const mailRecipient = [inputEmail];
        const mailSubject = Lang.getI18N("email.invitation.subject");
        const baseUrl = window.location.origin;

        const mailLink = `<a href='${baseUrl}?email=${inputEmail}' target='_blank'>se-dashboard.web.app</a>`;

        const mailBodyRegister = `${Lang.getI18N("email.invitation.body")} "${
          this.state.selectedStore?.name
        }", ${Lang.getI18N("email.invitation.body.register")}: ${mailLink}`;

        MailManager.sendEmail(mailRecipient, mailSubject, mailBodyRegister);
        Snackbars.display(
          `${Lang.getI18N("email.invitation.snackbar.sent")} ${mailRecipient}`
        );
      } else {
      }

      if (result.status == "ok") {
        this.state.owners = await getUserManager().getStoreOwners(
          this.state.selectedStore!
        );

        refs.ownersModal.$forceUpdate();

        Snackbars.display("L'utilisateur a été ajouté en propriétaire.");
      }
    },

    async updateStore() {
      let refs = <any>this.$refs;

      refs.modifyBtn.toggleLoading(true);

      if (
        this.state.initialStore.secureat_licence !=
        this.state.selectedStore.secureat_licence
      ) {
        this.state.initialStore.licence_activation_history.push(
          new ActivationHistory(
            new Date(),
            this.state.selectedStore.secureat_licence
          )
        );
      }
      if (this.state.selectedSticker != null) {
        this.state.selectedStore.koovea_sticker = this.state.selectedSticker.id;
      }
      await this.state.selectedStore!.set();

      refs.modifyBtn.toggleLoading(false);

      Snackbars.display(Lang.getI18N("saved-successfully"));
    },
    hasMasterRights() {
      let userRole = getAppState().userData!.role;
      return userRole == "admin" || userRole == "distributor";
    },

    manageTasks() {
      getStoreDatabaseState().set(this.state.selectedStore!);
      getState().navigate("storeDatabase");
    },
    openAccount(account: User) {
      getAccountState().selectedAccount = account;
      getState().navigate("account");
    },
    openOwnersModal() {
      let refs = <any>this.$refs;
      refs.ownersModal.open();
    },
    openDatabasesModal() {
      let refs = <any>this.$refs;
      refs.databasesModal.open();
    },
  },
  watch: {
    "state.selectedStore.secureat_licence"(newValue, oldValue) {
      if (newValue === false && oldValue) {
        Modals.open(
          "Attention : Désactiver la licence Secureat entrainera une interruption immediate des services de la tablette."
        );
      }
    },
  },
  data() {
    return {
      dbError: "",
      dateFormat: dateFormat,
      Lang: Lang,
      ModuleEnum: ModuleEnum,
      Role: Role,
    };
  },

  components: { SeButton, ProbeList },
});
