
import { defineComponent, nextTick, PropType, watch } from "vue";

export default defineComponent({
  expose: [
    "getSelectedItem",
    "changeTab",
    "getSelectedIndex",
    "changeTabIndex",
    "selectFirstIndex",
    "refresh",
    "hideIndex",
    "unhideIndex",
  ],
  props: {
    tabs: {
      type: Array as PropType<string[]>,
      required: true,
    },

    icons: {
      type: Array as PropType<string[]>,
      required: false,
      default: null,
    },
    onTabChange: {
      type: Function as PropType<(tab: number) => void>,
    },
    btnSize: {
      type: Number,
    },
    tabBackgroundColor: {
      type: String,
      default: "white",
    },
  },

  methods: {
    getParent() {
      return this.$el.parentNode;
    },
    getSelectedIndex(): number {
      return this.selectedIndex;
    },
    getSelectedItem(): string {
      return this.tabs[this.selectedIndex];
    },
    changeTabIndex(index: number) {
      var label = this.tabs[index];
      this.changeTab(label);
    },
    changeTab(label: string) {
      var refs = <any>this.$refs;

      let index = this.tabs.indexOf(label);
      this.selectedIndex = index;

      const tabs = document.querySelectorAll(".tab");

      let element: any = tabs[index];
      const tabBar = refs.tabBar;
      tabs.forEach((tab: any) => tab.classList.remove("active"));
      element.classList.add("active");

      this.resizeIndicator();

      const containerWidth = refs.tabContainer.offsetWidth;
      const elementWidth = element.offsetWidth;
      const elementOffsetLeft = element.offsetLeft;

      const scrollPosition =
        elementOffsetLeft - containerWidth / 2 + elementWidth / 2;

      refs.tabContainer.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });

      if (this.onTabChange != undefined) {
        this.onTabChange(index);
      }

      this.init = true;
    },

    onResize() {
      this.resizeIndicator();
    },

    unhideIndex(index: number) {
      let indexOf = this.hiddenIndexes.indexOf(index);

      if (indexOf != -1) {
        this.hiddenIndexes.splice(indexOf, 1);
      }
      this.selectFirstIndex();
    },
    hideIndex(index: number) {
      if (!this.hiddenIndexes.includes(index)) {
        this.hiddenIndexes.push(index);
      }

      this.selectFirstIndex();
    },

    resizeIndicator() {
      const tabs = document.querySelectorAll(".tab");
      let element: any = tabs[this.selectedIndex];
      const indicator: HTMLElement = document.querySelector(".indicator");
      indicator.style.width = `${element.offsetWidth}px`;
      indicator.style.left = `${element.offsetLeft}px`;
    },
    selectFirstIndex() {
      for (let i = 0; i < this.tabs.length; i++) {
        if (!this.hiddenIndexes.includes(i)) {
          this.changeTabIndex(i);
          break;
        }
      }
    },
    onScroll(a: any) {
      var refs = <any>this.$refs;

      if (!refs.stickContainer) {
        return;
      }
      var parent = this.getParent();

      if (parent == null) {
        return;
      }
      if (this.oldParentScrollTop - parent.scrollTop < 0) {
        refs.stickContainer.style.top = this.distanceFromTop - 80 + "px";
      } else {
        refs.stickContainer.style.top = this.distanceFromTop + "px";
      }

      this.oldParentScrollTop = parent.scrollTop;
    },
  },

  data() {
    return {
      selectedIndex: 0,
      oldParentScrollTop: 0,
      distanceFromTop: 0,
      init: false,
      hiddenIndexes: [] as Number[],
    };
  },

  mounted() {
    window.addEventListener("resize", this.resizeIndicator);

    this.getParent().addEventListener("scroll", this.onScroll);
    var rect = this.$el.getBoundingClientRect();
    this.distanceFromTop = rect.top - window.scrollY;

    nextTick(() => {
      if (!this.init) {
        this.selectFirstIndex();
      }
    });
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.resizeIndicator);
    this.getParent().removeEventListener("scroll", this.onScroll);
  },
});
