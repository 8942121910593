import { defineStore } from "pinia";
import Database from "@/model/Database";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { getState } from "../AppState";
import Audit from "@/model/Auditing/Audit";
import * as StringUtils from "@/utils/StringUtils";
import { Role } from "@/model/User";
import { getAuditManager } from "@/firebase/AuditManager";
import Communication from "@/model/Communication/Communication";
import { getCommunicationManager } from "@/firebase/CommunicationManager";
import CommunicationTarget from "@/model/Communication/CommunicationTarget";
import { DateTime } from "rrule/dist/esm/datetime";
import { collection, doc } from "firebase/firestore";
import * as Firebase from "@/firebase/Firebase";
import Quiz, { QuizQuestion } from "@/model/Communication/Quiz";
import Training from "@/model/Trainings/Training";
import TrainingSection from "@/model/Trainings/TrainingSection";
import { TrainingType } from "@/model/Trainings/TrainingType";

interface TrainingState {
  training: Training | null;

  initialTraining: Training | null;
  database: Database | null;
  creation: boolean;
  selectedQuestion: QuizQuestion | null;

  selectedSection: TrainingSection | null;
}

export const getTrainingState = defineStore("TrainingState", {
  state: (): TrainingState => {
    return {
      database: null,
      training: null,
      creation: false,
      selectedQuestion: null,
      initialTraining: null,
      selectedSection: null,
    };
  },
  actions: {
    set(database: Database | null, training: Training | null = null) {
      console.log(database);
      this.database = database;

      var appState = getState();

      if (training == null) {
        let source = null;

        if (appState.userData.role == Role.Admin) {
          var type = TrainingType.secureat;
        } else if (appState.userData.role == Role.Distributor) {
          if (database == null) {
            var type = TrainingType.distributor;
            source = appState.userData.distributor;
          } else {
            var type = TrainingType.database;
            source = database.ref;
          }
        } else if (appState.userData.role == Role.Manager) {
          var type = TrainingType.database;
          source = database.ref;
        }

        this.initialTraining = new Training(
          doc(collection(Firebase.firestore, "trainings")),
          true,
          appState.userData?.ref!,
          "",
          new CommunicationTarget(),
          type,
          [],
          source,
          "FR"
        );

        this.creation = true;
      } else {
        this.initialTraining = training;
        this.creation = false;
      }

      if (this.initialTraining.sections.length == 0) {
        this.initialTraining.sections.push(
          new TrainingSection("Nouvelle section", new Quiz([]), null)
        );
      }

      this.training = this.initialTraining.clone();

      this.selectedSection = this.training.sections[0];

      this.selectedQuestion = null;

      console.log(this.training);
    },
    async setup() {},
  },
});
