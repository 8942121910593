
import { defineComponent } from "@vue/runtime-core";
import * as Lang from "@/i18n/lang";
import { Question } from "@/model/Auditing/Question";
import AuditResultRow from "./AuditResultRow.vue";
import { truncateText } from "@/utils/StringUtils";
import { getAuditResultsState } from "@/pinia/audits/AuditResultsState";
import YesOrNoResultRow from "./YesOrNoResultRow.vue";
import FileUploadResultRow from "./FileUploadResultRow.vue";
import MultipleChoiceResultRow from "./MultipleChoiceResultRow.vue";

export default defineComponent({
  props: {
    question: {
      type: Question,
      required: true,
    },
  },
  mounted() {},

  async setup() {
    var state = getAuditResultsState();

    return {
      state: state,
    };
  },
  computed: {
    hasComments() {
      if (!this.question.answers) return false;
      return this.question.answers.some((answer) => answer.comment !== null);
    },
  },
  data() {
    return {
      Lang: Lang,
      truncateText: truncateText,
      isPercentageShown: false,
    };
  },
  components: {
    AuditResultRow,
    YesOrNoResultRow,
    FileUploadResultRow,
    MultipleChoiceResultRow,
  },
});
