
import { defineComponent } from "@vue/runtime-core";
import * as Lang from "@/i18n/lang";
import { Section } from "@/model/Auditing/Section";
import AuditResultTable from "@/components/auditing/results/AuditResultTable.vue";
import { truncateText } from "@/utils/StringUtils";

export default defineComponent({
  props: {
    section: {
      type: Section,
      required: true,
    },
  },

  methods: {
    calculateTotalResultScore(section: Section): number {
      let score = 0;

      for (let question of section.questions) {
        let fScore = 0;

        for (let answer of question.answers) {
          fScore += answer.score;
        }

        score += fScore / question.answers.length;
      }

      return score;
    },
  },
  data() {
    return {
      Lang: Lang,
      truncateText: truncateText,
    };
  },
  components: {
    AuditResultTable,
  },
});
