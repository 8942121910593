
import * as Lang from "@/i18n/lang";
import { getCommunicationStatsState } from "@/pinia/communications/CommunicationStatsState";
import { defineComponent } from "vue";
import WideCard from "@/components/global/WideCard.vue";
import * as Snackbars from "@/utils/Snackbars";
import * as DOMUtils from "@/utils/DOMUtils";
import CommunicationTargetStore from "@/components/communication/CommunicationTargetStore.vue";
import CommunicationQuiz from "@/components/communication/CommunicationQuiz.vue";
import * as Modals from "@/utils/Modals";
import CommunicationChart from "@/components/communication/CommunicationChart.vue";
import { DocumentReference } from "firebase/firestore";
import { getEmployeeManager } from "@/firebase/EmployeeManager";
import Employee from "@/model/Employee";
import * as DateUtils from "@/utils/DateUtils";
import { getStoreManager } from "@/firebase/StoreManager";
import Store from "@/model/Store";
import { getTrainingStatsState } from "@/pinia/trainings/TrainingStatsState";
import TrainingChart from "@/components/trainings/TrainingChart.vue";
import TrainingQuiz from "@/components/trainings/TrainingQuiz.vue";

export default defineComponent({
  async setup() {
    let state = getTrainingStatsState();
    await state.setup();

    return {
      state: state,
    };
  },
  data() {
    return {
      Lang: Lang,
      DateUtils: DateUtils,
      searchResults: false,
    };
  },

  mounted() {
    this.searchResults = false;
  },

  methods: {
    openStatModal(store: Store) {
      this.state.visibleResults = this.state.results.items.filter(
        (x) => x.store.id == store.ref.id
      );

      this.state.loadEmployees();
      var refs = <any>this.$refs;
      refs.statModal.open();
    },

    getStoreName(ref: DocumentReference) {
      return getStoreManager().getStoreFromCacheOnly(ref.id)?.name;
    },
    getEmployeeName(ref: DocumentReference) {
      var employee = this.state.employees.find((x) => x.ref.id == ref.id);

      if (!employee) {
        return "";
      }
      return employee?.name + " " + employee?.surname;
    },
  },
  components: {
    WideCard,
    CommunicationTargetStore,
    TrainingQuiz,
    TrainingChart,
  },
});
