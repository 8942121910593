
import NavHeader from "@/nav/NavHeader.vue";
import SearchBar from "@/components/global/SearchBar.vue";
import ProbeList from "@/components/probes/ProbeList.vue";
import { defineComponent } from "vue";
import { getProbeState } from "@/pinia/probes/ProbeState";
import * as Lang from "@/i18n/lang";
import ProbeChart from "./ProbeChart.vue";
import { getProbesManager } from "@/firebase/ProbeManager";
import * as Snackbars from "@/utils/Snackbars";
import { getState as getAppState } from "@/pinia/AppState";
import { getStoreManager } from "@/firebase/StoreManager";
import Store from "@/model/Store";
import BatteryChart from "./BatteryChart.vue";

export default defineComponent({
  methods: {
    openBatteryModal() {
      var refs = <any>this.$refs;
      refs.batteryModal.open();

      let currentDate = new Date();
      let currentUtcDate = new Date(currentDate.toUTCString());

      let start = new Date(Date.UTC(2024, 5, 26, 14, 5));

      refs.batteryChart.updateGraph(start, currentUtcDate);
    },
    async applyGraph() {
      var refs = <any>this.$refs;
      await refs.graph.updateGraph(
        refs.calendar.getStart(),
        refs.calendar.getEnd()
      );
    },
    async removeOwnership() {
      var result = await getProbesManager().removeOwnership(this.state.probe!);
      if (result) {
        this.storeOwner = null;
      }
    },
    async updateProbe() {
      var refs = <any>this.$refs;

      this.state.probe!.postingInterval =
        this.state.postingIntervalMinutes * 60;

      if (refs.modifyBtn.isLoading()) {
        return;
      }

      refs.modifyBtn.toggleLoading(true);
      var result = await getProbesManager().editProbe(this.state.probe!);
      Snackbars.display(Lang.getI18N("saved-successfully"));
      refs.modifyBtn.toggleLoading(false);
    },
  },

  async setup() {
    var state = getProbeState();

    return {
      state: state,
    };
  },
  data() {
    return {
      storeOwner: null as Store | null,
      Lang: Lang,
    };
  },
  async mounted() {
    var refs = <any>this.$refs;

    var start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    refs.calendar.setStart(start);
    refs.calendar.setEnd(new Date());

    if (getAppState().connectedToApi) {
      await this.applyGraph();
    }

    this.storeOwner = getStoreManager().getStoreFromCacheOnly(
      this.state.probe!.storeId!
    );
  },
  components: {
    ProbeChart,
    BatteryChart,
  },
});
