import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  addDoc,
  doc,
  documentId,
  updateDoc,
  setDoc,
  arrayUnion,
  DocumentReference,
  limit,
  Query,
  DocumentData,
  FieldPath,
  orderBy,
} from "firebase/firestore";

import { getState } from "../pinia/AppState";

import { defineStore } from "pinia";
import {
  getStorage,
  deleteObject,
  ref,
  getDownloadURL,
} from "firebase/storage";

import { SnapshotListener } from "@/utils/SnapshotListener";
import Employee from "@/model/Employee";
import * as Firebase from "@/firebase/Firebase";

interface EmployeeManager {
  cache: Map<string, Employee>;
}

export const getEmployeeManager = defineStore("EmployeesManager", {
  state: (): EmployeeManager => {
    return {
      cache: new Map<string, Employee>(),
    };
  },
  actions: {
    async getEmployees(storeRef: DocumentReference): Promise<Employee[]> {
      let res = await getDocs(
        query(
          collection(Firebase.firestore, "employees"),
          where("stores", "array-contains", storeRef)
        )
      );
      var results = res.docs.map((x) => Employee.fromFirestore(x));

      for (let employee of results) {
        this.cache.set(employee.ref.id, employee);
      }

      var oldPath = storeRef.path + "/employees";

      console.log("b");
      let oldResults = await getDocs(
        query(collection(Firebase.firestore, oldPath))
      );

      var oldEmployees = oldResults.docs.map((x) => Employee.fromFirestore(x));

      for (let oldEmployee of oldEmployees) {
        if (
          results.some(
            (x) => x.old_ref != null && x.old_ref.id == oldEmployee.ref.id
          )
        ) {
          continue;
        } else {
          results.push(oldEmployee);
        }
      }

      return results.sort((a, b) => a.name.localeCompare(b.name));
    },
    async getEmployee(ref: DocumentReference): Promise<Employee> {
      var result = this.cache.get(ref.id!);

      if (result) {
        return result;
      }

      let doc = await getDoc(ref);
      var employee = Employee.fromFirestore(doc);
      this.cache.set(ref.id, employee);
      return employee;
    },
  },
});
