import {
  collection,
  query,
  where,
  getDocs,
  DocumentData,
  Query,
  or,
} from "firebase/firestore";

import User, { Role } from "../model/User";
import { defineStore } from "pinia";
import { SnapshotListener } from "@/utils/SnapshotListener";
import Training from "@/model/Trainings/Training";
import TrainingResult from "@/model/Trainings/TrainingResult";
import * as Firebase from "@/firebase/Firebase";
import Database from "@/model/Database";

interface TrainingManager {}

export const getTrainingManager = defineStore("TrainingManager", {
  state: (): TrainingManager => {
    return {};
  },
  actions: {
    async getTrainings(
      user: User,
      database: Database | null = null
    ): Promise<Training[]> {
      let q: Query<DocumentData> | null = null;

      if (user.role == Role.Manager) {
        q = query(
          collection(Firebase.firestore, "trainings"),
          where("source", "==", database.ref)
        );
      } else if (user.role == Role.Admin) {
        q = query(
          collection(Firebase.firestore, "trainings"),
          where("type", "==", "secureat")
        );
      } else if (user.role == Role.Distributor) {
        if (database == null) {
          q = query(
            collection(Firebase.firestore, "trainings"),
            where("source", "==", user.distributor)
          );
        } else {
          q = query(
            collection(Firebase.firestore, "trainings"),
            or(
              where("source", "==", database.ref),
              where("source", "==", user.distributor)
            )
          );
        }
      }

      let snapshot = await getDocs(q);

      return snapshot.docs.map((x) => Training.fromFirestore(x));
    },

    async getTrainingResusts(training: Training) {
      let path = training.ref.path + "/results";

      const q = query(
        collection(Firebase.firestore, path),
        where("completed_timestamp", "!=", null)
      );

      return new SnapshotListener<TrainingResult>(
        TrainingResult.fromFirestore,
        q
      );
    },
  },
});
