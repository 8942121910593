import {
  DocumentSnapshot,
  DocumentReference,
  Timestamp,
} from "firebase/firestore";
import SecureatSnapshot from "./SecureatSnapshot";
import Employee from "./Employee";
import * as ImageKit from "@/utils/ImageUtils";
import ActionHistory from "@/model/ActivationHistory";
import ActivationHistory from "@/model/ActivationHistory";

export default class Score {
  score: number = 0;
  date: Date;

  constructor(score: number, date: Date) {
    this.score = score;
    this.date = date;
  }

  public static fromJson(x: any): Score {
    let result = new Score(x.score, x.date.toDate());
    return result;
  }

  public static computeWeeklyScore(scores: Score[]): number {
    let total = scores.length;

    let sumCoeff = 0;

    let i = 1;

    let delta = 0;

    for (let score of scores) {
      let coeff = i / total;

      sumCoeff += coeff;

      delta += score.score * coeff;

      i++;
    }
    let result = delta / sumCoeff;

    return Math.round(result * 10) / 10;
  }
}
