import {
  DocumentSnapshot,
  DocumentReference,
  doc,
  Firestore,
  collection,
} from "firebase/firestore";
import DatabaseTaskConfiguration from "./DatabaseTaskConfiguration";
import SecureatSnapshot from "../SecureatSnapshot";
import TaskConfiguration from "./TaskConfiguration";
import * as Firebase from "@/firebase/Firebase";
import Store from "../Store";
import TaskModuleConfiguration from "./config/TaskModuleConfiguration";
import { getTaskConfigurationManager } from "@/firebase/TaskConfigurationManager";
import { getModuleFromRef } from "@/firebase/ModuleManager";

export default class StoreTaskConfiguration extends TaskConfiguration {
  origin: DocumentReference | null = null;
  originValue: DatabaseTaskConfiguration | null = null;
  existRemote: boolean = true;

  constructor(
    ref: DocumentReference,
    active: boolean | null = null,
    configuration: TaskModuleConfiguration | null = null,
    description: string | null = null,
    durations: number[] | null = null,
    module: DocumentReference | null = null,
    origin: DocumentReference | null = null,
    name: string | null = null,
    recurrence: string | null = null,
    starts: number[] | null = null,
    requires_manager: boolean | null = null,
    type: DocumentReference | null = null,
    notify_late: boolean | null = null
  ) {
    super(
      ref,
      active,
      configuration,
      description,
      durations,
      module,
      name,
      recurrence,
      starts,
      requires_manager,
      type,
      notify_late
    );

    this.origin = origin;
  }
  public override isLocal(): boolean {
    return this.origin == null;
  }
  public override getActive() {
    return this.originValue == null
      ? super.getActive()
      : this.originValue?.active_lock || this.active == null
      ? this.originValue?.getActive()
      : super.getActive();
  }

  public override getConfiguration() {
    return this.originValue == null
      ? super.getConfiguration()
      : this.originValue?.configuration_lock || this.configuration == null
      ? this.originValue?.getConfiguration()
      : super.getConfiguration();
  }

  public override getDescription() {
    return this.originValue == null
      ? super.getDescription()
      : this.originValue?.description_lock || this.description == null
      ? this.originValue?.getDescription()
      : super.getDescription();
  }
  public getDurations() {
    return this.originValue == null
      ? super.getDurations()
      : this.originValue?.schedule_lock || this.durations == null
      ? this.originValue?.getDurations()
      : super.getDurations();
  }
  public getModule() {
    return this.originValue == null
      ? super.getModule()
      : this.originValue?.getModule();
  }
  public getName() {
    return this.originValue == null
      ? super.getName()
      : this.originValue?.name_lock || this.name == null
      ? this.originValue?.getName()
      : super.getName();
  }
  public getType() {
    return this.originValue == null
      ? super.getType()
      : this.originValue?.type_lock || this.type == null
      ? this.originValue?.getType()
      : super.getType();
  }
  public getNotifyLate(): boolean {
    return this.originValue == null
      ? super.getNotifyLate()
      : this.originValue?.notify_late_lock || this.notify_late == null
      ? this.originValue?.getNotifyLate()
      : super.getNotifyLate();
  }
  public getRecurrence() {
    return this.originValue == null
      ? super.getRecurrence()
      : this.originValue?.recurrence_lock || this.recurrence == null
      ? this.originValue?.getRecurrence()
      : super.getRecurrence();
  }
  public getStarts() {
    return this.originValue == null
      ? super.getStarts()
      : this.originValue?.schedule_lock || this.starts == null
      ? this.originValue?.getStarts()
      : super.getStarts();
  }
  public getRequiresManager() {
    return this.originValue == null
      ? super.getRequiresManager()
      : this.originValue?.requires_manager_lock || this.requires_manager == null
      ? this.originValue?.getRequiresManager()
      : super.getRequiresManager();
  }

  public setOriginValue(origin: DatabaseTaskConfiguration) {
    this.originValue = origin;
  }
  public static fromFirestore(
    snapshot: DocumentSnapshot
  ): StoreTaskConfiguration {
    const data = snapshot.data()!;

    var module = getModuleFromRef(data.module)?.toEnum();

    let result = new StoreTaskConfiguration(
      snapshot.ref,
      data.active,
      getTaskConfigurationManager().deserializeConfiguration(
        data.configuration,
        module!
      ),
      data.description,
      data.durations,
      data.module,
      data.origin,
      data.name,
      data.recurrence,
      data.starts,
      data.requires_manager,
      data.type,
      data.notify_late == undefined ? false : data.notify_late
    );

    if (result.starts != null && result.durations != null) {
      /* Temporary sanitize */
      if (
        result.starts!.constructor != Array ||
        result.durations!.constructor != Array
      ) {
        result.starts = Array.from(
          <any>result.starts!,
          ([name, value]) => value
        );
        result.durations = Array.from(
          <any>result.durations!,
          ([name, value]) => value
        );
      }
    }

    return result;
  }

  public toFirestore(): any {
    return {
      active: this.active,
      configuration: this.configuration?.toFirebase(),
      description: this.description,
      durations: this.durations,
      module: this.module,
      origin: this.origin,
      name: this.name,
      recurrence: this.recurrence,
      starts: this.starts,
      requires_manager: this.requires_manager,
      type: this.type,
      notify_late: this.notify_late,
    };
  }
  public static fromOrigin(
    originValue: DatabaseTaskConfiguration,
    store: Store
  ): StoreTaskConfiguration {
    let result = new StoreTaskConfiguration(
      doc(
        collection(Firebase.firestore, store.ref.path, "task_configurations")
      ),
      null,
      null,
      null,
      null,
      null,
      originValue.ref!,
      null,
      null,
      null,
      null,
      null,
      null
    );

    result.setOriginValue(originValue);

    return result;
  }
  public static duplicate(
    obj: StoreTaskConfiguration,
    store: Store
  ): StoreTaskConfiguration {
    let configuration = obj.getConfiguration().clone();

    let durations = obj.getDurations();

    if (durations != null) {
      durations = durations.map((x) => x);
    }

    let starts = obj.getStarts();

    if (starts != null) {
      starts = starts.map((x) => x);
    }

    return new StoreTaskConfiguration(
      doc(
        collection(Firebase.firestore, store.ref.path, "task_configurations")
      ),
      obj.getActive()!,
      configuration,
      obj.getDescription()!,
      durations,
      obj.getModule()!,
      null,
      obj.getName()!,
      obj.getRecurrence()!,
      starts,
      obj.getRequiresManager()!,
      obj.getType(),
      obj.getNotifyLate()
    );
  }

  public static clone(obj: StoreTaskConfiguration): StoreTaskConfiguration {
    let configuration =
      obj.configuration != null ? obj.configuration.clone() : null;

    let durations = obj.durations != null ? obj.durations.map((x) => x) : null;

    let starts = obj.starts != null ? obj.starts.map((x) => x) : null;

    let result = new StoreTaskConfiguration(
      obj.ref,
      obj.active!,
      configuration,
      obj.description!,
      durations,
      obj.module!,
      obj.origin,
      obj.name!,
      obj.recurrence,
      starts,
      obj.requires_manager,
      obj.type,
      obj.notify_late
    );

    result.existRemote = obj.existRemote;

    result.setOriginValue(obj.originValue!);
    return result;
  }

  public hasScheduleOverride(): boolean {
    return this.starts != null || this.durations != null;
  }
  public toJson(): Record<string, any> {
    const baseJson = super.toJson();

    return {
      ...baseJson,
      origin: this.origin ? this.origin.path : null,
    };
  }

  public static fromJson(
    json: Record<string, any>,
    db: Firestore
  ): StoreTaskConfiguration {
    const moduleRef: DocumentReference = doc(db, json.module);
    let originRef: DocumentReference | null = null;
    if (json.origin !== null) {
      originRef = doc(db, json.origin);
    }

    throw new Error();
    /*return new StoreTaskConfiguration(
      null,
      json.active,
      json.configuration,
      json.description,
      json.durations,
      moduleRef,
      originRef,
      json.name,
      json.recurrence,
      json.starts,
      json.requires_manager,
      json.type
    ); */
  }
}
