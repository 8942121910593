import { DocumentSnapshot, DocumentReference } from "firebase/firestore";
import SecureatSnapshot from "./SecureatSnapshot";
import SecureatIllustration from "./utils/SecureatIllustration";

export default class Database extends SecureatSnapshot {
  name: string = "";
  hidden_modules: DocumentReference[] = [];
  illustration_url: SecureatIllustration | null = null;
  distributor: DocumentReference | null = null;
  managers: DocumentReference[] = [];
  stores: DocumentReference[] = [];
  store_access: boolean;
  hidden: boolean;
  hidden_extensions: string[] = [];

  /* Transcient */

  constructor(
    ref: DocumentReference,
    name: string,
    hidden_modules: DocumentReference[],
    illustration_url: SecureatIllustration | null,
    distributor: DocumentReference | null = null,
    managers: DocumentReference[] = [],
    stores: DocumentReference[] = [],
    store_access: boolean = false,
    hidden: boolean = false,
    hidden_extensions: string[] = []
  ) {
    super(ref);
    this.name = name;
    this.hidden_modules = hidden_modules;
    this.illustration_url = illustration_url;
    this.distributor = distributor;
    this.managers = managers;
    this.stores = stores;
    this.store_access = store_access;
    this.hidden = hidden;
    this.hidden_extensions = hidden_extensions;
  }

  public static fromFirestore(snapshot: DocumentSnapshot): Database {
    const data = snapshot.data()!;

    let result = new Database(
      snapshot.ref,
      data.name,
      data.hidden_modules == undefined ? [] : data.hidden_modules,
      data.illustration_url == undefined
        ? null
        : SecureatIllustration.fromFirestore(data.illustration_url),
      data.distributor == undefined ? null : data.distributor,
      data.managers == undefined ? [] : data.managers,
      data.stores == undefined ? [] : data.stores,
      data.store_access == undefined ? false : data.store_access,
      data.hidden == undefined ? false : data.hidden,
      data.hidden_extensions == undefined ? [] : data.hidden_extensions
    );

    return result;
  }

  public toFirestore() {
    return {
      name: this.name,
      hidden_modules: this.hidden_modules,
      illustration_url: this.illustration_url.toFirestore(),
      distributor: this.distributor,
      managers: this.managers,
      stores: this.stores,
      store_access: this.store_access,
      hidden: this.hidden,
      hidden_extensions: this.hidden_extensions,
    };
  }

  public static clone(d: Database): Database {
    return new Database(
      d.ref,
      d.name,
      d.hidden_modules,
      d.illustration_url ? d.illustration_url.clone() : null,
      d.distributor,
      d.managers.map((x) => x),
      d.stores.map((x) => x),
      d.store_access,
      d.hidden,
      d.hidden_extensions.map((x) => x)
    );
  }
}
