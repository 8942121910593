import { ProfilingChartType } from "@/components/home/ProfilingChartType";
import { KooveaSticker } from "@/model/Koovea/KooveaSticker";
import Probe from "@/model/Probe";
import ProbeTemperature from "@/model/ProbeTemperature";
import Score from "@/model/Score";
import User from "@/model/User";
import { getIdToken } from "firebase/auth";
import * as http from "http";
const config = require("@/config.json");

export async function exportStoreElements(
  elementIds: string[],
  sourceStore: string,
  targetStore: string
) {
  return await httpRequest(
    "POST",
    config.apiHost + "stores/copyStoreElements",

    JSON.stringify({
      sourceId: sourceStore,
      targetId: targetStore,
      objectIds: elementIds,
    }),
    "application/json"
  );
}
export async function exportStoreTaskConfigurations(
  taskIds: string[],
  sourceStore: string,
  targetStore: string
) {
  return await httpRequest(
    "POST",
    config.apiHost + "stores/copyStoreTaskConfs",

    JSON.stringify({
      sourceId: sourceStore,
      targetId: targetStore,
      objectIds: taskIds,
    }),
    "application/json"
  );
}

export async function exportDatabaseElements(
  elementIds: string[],
  sourceDatabase: string,
  targetDatabase: string
) {
  return await httpRequest(
    "POST",
    config.apiHost + "database/copyDatabaseElements",

    JSON.stringify({
      sourceId: sourceDatabase,
      targetId: targetDatabase,
      objectIds: elementIds,
    }),
    "application/json"
  );
}

export async function exportDatabaseTaskConfigurations(
  taskConfigurationIds: string[],
  sourceDatabase: string,
  targetDatabase: string
) {
  return await httpRequest(
    "POST",
    config.apiHost + "database/copyDatabaseTaskConfs",

    JSON.stringify({
      sourceId: sourceDatabase,
      targetId: targetDatabase,
      objectIds: taskConfigurationIds,
    }),
    "application/json"
  );
}

export async function getProfilingData() {
  return await httpRequest("GET", config.apiHost + "profiling/live");
}

export async function getIncidents() {
  return await httpRequest("GET", config.apiHost + "incidents");
}
export async function searchTaskGenerationEvent(
  start: Date,
  end: Date,
  storeId: string
) {
  return await httpRequest(
    "POST",
    config.apiHost + "task_generation/search",

    JSON.stringify({
      start: start.toISOString(),
      end: end.toISOString(),
      storeId: storeId,
    }),
    "application/json"
  );
}

export async function signup(
  name: string,
  surname: string,
  email: string,
  password: string,
  phone: string
) {
  return await httpRequest(
    "POST",
    config.apiHost + "auth/signup",
    JSON.stringify({
      name: name,
      surname: surname,
      email: email,
      password: password,
      phone: phone,
      distributorId: null,
      databaseId: null,
      storeId: null,
      role: null,
    }),
    "application/json"
  );
}

export async function createUser(
  name: string,
  surname: string,
  email: string,
  password: string,
  phone: string,
  distributorId: string | null,
  databaseId: string | null,
  storeId: string | null,
  role: string
) {
  return await httpRequest(
    "POST",
    config.apiHost + "auth/registerAccount",
    JSON.stringify({
      name: name,
      surname: surname,
      email: email,
      password: password,
      phone: phone,
      distributorId: distributorId,
      databaseId: databaseId,
      storeId: storeId,
      role: role,
    }),
    "application/json"
  );
}
export async function connect(currentUser: any) {
  var token = await getIdToken(currentUser);

  return await httpRequest(
    "POST",
    config.apiHost + "auth",
    JSON.stringify({
      token: token,
    }),
    "application/json"
  );
}

export async function createGCoreUpload(file: File) {
  return await httpRequest(
    "GET",
    config.apiHost + "gcore/uploadToken?video_name=" + file.name
  );
}

export async function deleteGCoreVideo(videoId: string) {
  return await httpRequest(
    "GET",
    config.apiHost + "gcore/deleteVideo?videoId=" + videoId
  );
}

export async function getOuput() {
  return await httpRequest("GET", config.apiHost + "profiling/output");
}

export async function getProbe(uid: string) {
  let result = await httpRequest("GET", config.apiHost + "probe/" + uid);
  return Probe.fromJson(result);
}

export async function getProbes() {
  let result = await httpRequest("GET", config.apiHost + "probes");

  return result.map((x: any) => Probe.fromJson(x));
}

export async function getProbesOfStore(storeId: string) {
  let result = await httpRequest(
    "GET",
    config.apiHost + "probes/store/" + storeId
  );

  return result.map((x: any) => Probe.fromJson(x));
}

export async function getProbeGraph(probeId: string, start: Date, end: Date) {
  const utcStart = new Date(
    Date.UTC(
      start.getUTCFullYear(),
      start.getUTCMonth(),
      start.getUTCDate(),
      start.getUTCHours(),
      start.getUTCMinutes(),
      start.getUTCSeconds(),
      start.getUTCMilliseconds()
    )
  );

  const utcEnd = new Date(
    Date.UTC(
      end.getUTCFullYear(),
      end.getUTCMonth(),
      end.getUTCDate(),
      end.getUTCHours(),
      end.getUTCMinutes(),
      end.getUTCSeconds(),
      end.getUTCMilliseconds()
    )
  );
  let result = await httpRequest(
    "POST",
    config.apiHost + "probes/temperatures/" + probeId,

    JSON.stringify({
      start: utcStart.toISOString(),
      end: utcEnd.toISOString(),
    }),
    "application/json"
  );

  return result.map((x: any) => ProbeTemperature.fromJson(x));
}
export async function removeOwnership(probe: Probe) {
  let result = await httpRequest(
    "POST",
    config.apiHost + "probes/removeOwnership/" + probe.probeUID
  );

  return result.status === "ok";
}
export async function addManager(email: string, databaseId: string) {
  return await httpRequest(
    "POST",
    config.apiHost + "database/addManager",
    JSON.stringify({
      email: email,
      databaseId: databaseId,
    }),
    "application/json"
  );
}
export async function addStoreOwner(email: string, storeId: string) {
  return await httpRequest(
    "POST",
    config.apiHost + "stores/addOwner",
    JSON.stringify({
      email: email,
      storeId: storeId,
    }),
    "application/json"
  );
}
export async function editProbe(probe: Probe) {
  let result = await httpRequest(
    "POST",
    config.apiHost + "probes/edit/" + probe.probeUID,
    JSON.stringify({
      name: probe.name,
      maximumTemperature: probe.maximumTemperature,
      minimumTemperature: probe.minimumTemperature,
      postingInterval: probe.postingInterval,
      active: probe.active,
      pushNotification: probe.pushNotification,
    }),
    "application/json"
  );

  return result;
}

export async function getVideoDownloadLink(videoId: string) {
  let result = await httpRequest(
    "GET",
    config.apiHost + "gcore/downloadLink?videoId=" + videoId
  );

  return result.link;
}

export async function getKooveaTags(
  distributorId: string,
  userId: string
): Promise<KooveaTag[]> {
  let tags = await httpRequest(
    "GET",
    config.apiHost +
      "koovea/tags?distributorId=" +
      distributorId +
      "&userId=" +
      userId
  );

  tags.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  return tags;
}
export async function getKooveaStickers(
  distributorId: string,
  requestingStoreId: string
): Promise<KooveaSticker[]> {
  return await httpRequest(
    "GET",
    config.apiHost +
      "koovea/stickers?distributorId=" +
      distributorId +
      "&requestingStoreId=" +
      requestingStoreId
  );
}

export async function validKooveaCredentials(
  email: string,
  password: string
): Promise<any> {
  return await httpRequest(
    "POST",
    config.apiHost + "koovea/validCredentials",
    JSON.stringify({
      email: email,
      password: password,
    }),
    "application/json"
  );
}

export async function createLoginToken(uid: string): Promise<any> {
  let result = await httpRequest(
    "POST",
    config.apiHost + "auth/createToken",
    JSON.stringify({
      UId: uid,
    }),
    "application/json"
  );

  return result.token;
}

export async function getIllustrationProposal(search: string): Promise<any> {
  var result = await httpRequest(
    "GET",
    config.apiHost + "illustration/proposals?search=" + search,
    "application/json"
  );
  return result.items;
}

export function getLinkWithoutCors(url: string) {
  return config.apiHost + "illustration/no-cors?imageUrl=" + url;
}

export async function getScores(storeId: string): Promise<Score[]> {
  var result = await httpRequest(
    "GET",
    config.apiHost + "scores/weekly?storeId=" + storeId
  );

  if (result == null) {
    return [];
  }
  var res = result.map((x: any) => new Score(x.score, x.date));

  console.log(res);
  return res;
}

export function httpRequest(
  method: string,
  url: string,
  body: any = null,
  contentType: string = "application/octet-stream"
): Promise<any | null> {
  return new Promise((resolve) => {
    var xhr = new XMLHttpRequest();

    xhr.withCredentials = true;

    xhr.open(method, url, true);

    xhr.onerror = (e) => {
      return resolve(e);
    };

    xhr.setRequestHeader("Content-Type", contentType);

    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          if (xhr.responseText != "") {
            return resolve(JSON.parse(xhr.responseText));
          } else {
            return resolve(null);
          }
        } else {
          resolve(null);
        }
      }
    };

    if (body == null) {
      xhr.send();
    } else {
      xhr.send(body);
    }
  });
}
