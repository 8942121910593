import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-55b63456"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex col" }
const _hoisted_2 = { class: "flex justify-between align-center" }
const _hoisted_3 = { class: "text-muted" }
const _hoisted_4 = { class: "grid custom-grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeCheckBox = _resolveComponent("SeCheckBox")!
  const _component_ToggleSwitch = _resolveComponent("ToggleSwitch")!
  const _component_WideCard = _resolveComponent("WideCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SeCheckBox, {
        ref: "selectAll",
        onClick: _ctx.onSelectAllClicked
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.Lang.getI18N("select-all")), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      (_ctx.displayAutomatic())
        ? (_openBlock(), _createBlock(_component_ToggleSwitch, {
            key: 0,
            click: _ctx.onToggleClick,
            ref: "automaticSwitch",
            modelValue: _ctx.automatic,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.automatic) = $event))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Prise automatique")
            ]),
            _: 1
          }, 8, ["click", "modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedElements, (item, index) => {
        return (_openBlock(), _createBlock(_component_WideCard, {
          key: index,
          obj: item,
          icon: 
          item.getIllustrationUrl() != '' && item.getIllustrationUrl() != null
            ? item.getIllustrationUrl()
            : '/ico/equipement.svg'
        ,
          onClick: ($event: any) => (_ctx.toggleCheckBox(item))
        }, {
          subtitle: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.getName()), 1)
          ]),
          footer: _withCtx(() => [
            _createVNode(_component_SeCheckBox, {
              onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
              ref_for: true,
              ref: index,
              style: {"align-self":"center"},
              checked: _ctx.alreadySelected(item),
              id: item.ref.id
            }, null, 8, ["checked", "id"])
          ]),
          _: 2
        }, 1032, ["obj", "icon", "onClick"]))
      }), 128))
    ])
  ]))
}