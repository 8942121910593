import { defineStore } from "pinia";
import User from "@/model/User";
import Store from "@/model/Store";
import Task from "@/model/Task";
import DatabaseTaskConfiguration from "@/model/Tasks/DatabaseTaskConfiguration";
import Database from "@/model/Database";
import Element from "@/model/Elements/Element";
import TaskConfiguration from "@/model/Tasks/TaskConfiguration";
import { getState } from "../AppState";
import { DocumentReference, getDoc, getDocFromCache } from "firebase/firestore";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { getTaskConfigurationManager } from "@/firebase/TaskConfigurationManager";
import { getElementManager } from "@/firebase/ElementManager";
import { getStoreManager } from "@/firebase/StoreManager";
import Employee from "@/model/Employee";
import { getEmployeeManager } from "@/firebase/EmployeeManager";
import EmployeeRole from "@/model/EmployeeRole";

interface StoreDatabaseState {
  store: Store | null;
  tabIndex: number;
  database: Database | null;
  elements: Element[] | null;
  tasks: TaskConfiguration[] | null;
  elementSearch: string;
  taskSearch: string;
  scrollY: number;
  displayUnactives: boolean;
  employees: Employee[];
  roles: EmployeeRole[];
}

export const getStoreDatabaseState = defineStore("storeDatabaseState", {
  state: (): StoreDatabaseState => {
    return {
      store: null,
      tabIndex: 0,
      database: null,
      elements: [],
      tasks: [],
      elementSearch: "",
      taskSearch: "",
      scrollY: 0,
      displayUnactives: false,
      employees: [],
      roles: [],
    };
  },
  actions: {
    set(store: Store) {
      this.store = store;
    },
    getRoleName(ref: DocumentReference) {
      var role = this.roles.find((x) => x.ref.id == ref.id);

      if (!role) {
        console.log("role " + ref.id + "  is null");
        return null;
      }

      return role.name;
    },
    async setup() {
      let appState = getState();

      if (appState.customStore != null) {
        this.store = appState.customStore;
      }

      await getStoreManager().initialize();

      this.database = await getDatabaseManager().getDatabaseByReference(
        this.store!.database!
      );

      let taskConfigurationManager = getTaskConfigurationManager();

      await taskConfigurationManager.ensureDatabaseTaskConfigurationListener(
        this.store?.database?.id!
      );

      await taskConfigurationManager.ensureStoreTaskConfigurationListener(
        this.store?.ref?.id!
      );

      let elementManager = getElementManager();

      await elementManager.ensureDatabaseElementListener(
        this.store?.database?.id!
      );

      await elementManager.ensureStoreElementListener(this.store?.ref?.id!);

      this.elements = elementManager.getElements(this.store!);

      this.tasks = taskConfigurationManager.getTaskConfigurations(this.store!);

      elementManager.getDatabaseElementListener(
        this.store?.database?.id!
      ).updated = () => {
        this.elements = elementManager.getElements(this.store!);
      };

      elementManager.getStoreElementListener(this.store?.ref?.id!).updated =
        () => {
          this.elements = elementManager.getElements(this.store!);
        };

      this.employees = await getEmployeeManager().getEmployees(this.store.ref);

      this.roles = await getDatabaseManager().getEmployeeRoles(
        this.store.database
      );
    },
  },
});
