import { defineStore } from "pinia";
import Database from "@/model/Database";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { getState } from "../AppState";
import * as StringUtils from "@/utils/StringUtils";
import { Role } from "@/model/User";
import { getAuditManager } from "@/firebase/AuditManager";
import Communication from "@/model/Communication/Communication";
import { getCommunicationManager } from "@/firebase/CommunicationManager";
import { getStoreManager } from "@/firebase/StoreManager";
import Training from "@/model/Trainings/Training";
import { ExtensionEnum } from "@/model/ExtensionEnum";

interface TrainingsState {
  trainings: Training[];
  displayUnactives: boolean;
  databases: Database[];
  selectedDatabase: Database | null;
}

export const getTrainingsState = defineStore("TrainingsState", {
  state: (): TrainingsState => {
    return {
      trainings: [],
      displayUnactives: false,
      databases: [],
      selectedDatabase: null,
    };
  },
  actions: {
    async setup() {
      var dbManager = getDatabaseManager();

      await dbManager.initialize();

      await getStoreManager().initialize();

      this.databases = dbManager
        .getDatabases()
        .filter((x) => !x.hidden_extensions.includes(ExtensionEnum.Training));

      this.databases.sort((a, b) => a.name.localeCompare(b.name));

      this.communications = [];

      if (this.databases.length > 0 && this.selectedDatabase == null) {
        this.selectedDatabase = this.databases[0];
        this.communications = await getCommunicationManager().getCommunications(
          this.selectedDatabase
        );
      }
    },
  },
});
