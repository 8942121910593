import { createApp, h } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";
import SeInput from "./components/global/SeInput.vue";
import SeButton from "./components/global/SeButton.vue";
import SeFadeButton from "./components/global/SeFadeButton.vue";

import WideCard from "./components/global/WideCard.vue";
import NavHeader from "./components/nav/NavHeader.vue";
import ToggleSwitch from "./components/global/ToggleSwitch.vue";
import SeModal from "./components/global/SeModal.vue";
import SeInputModal from "@/components/global/SeInputModal.vue";
import SeDeleteConfirmModal from "@/components/global/SeDeleteConfirmModal.vue";
import SeSimpleTab from "./components/global/SeSimpleTab.vue";
import SearchBar from "./components/global/SearchBar.vue";
import SeMultiFilter from "./components/global/SeMultiFilter.vue";
import SeTab from "./components/global/SeTab.vue";
import { HalfCircleSpinner } from "epic-spinners";
import LockedField from "./components/global/LockedField.vue";
import SeRadio from "./components/global/SeRadio.vue";
import SeCheckBox from "./components/global/SeCheckBox.vue";
import SeImage from "./components/global/SeImage.vue";
import SeDropdownFilter from "./components/global/SeDropdownFilter.vue";
import SeTextarea from "./components/global/SeTextarea.vue";
import SeCalendarRanged from "@/components/global/SeCalendarRanged.vue";
import SeLoadingIndicator from "@/components/global/SeLoadingIndicator.vue";
import ModuleSearchResult from "@/components/tasks/ModuleResultSearch.vue";
import SeExpendable from "@/components/global/SeExpendable.vue";
import SeTable from "@/components/global/SeTable.vue";
import SeInputFile from "@/components/global/SeInputFile.vue";
import SeIconDropdown from "@/components/global/SeIconDropdown.vue";
import ToggleIcon from "@/components/global/ToggleIcon.vue";
import ReadonlyField from "@/components/global/ReadonlyField.vue";
import SeVerticalTab from "@/components/global/SeVerticalTab.vue";
import SeForm from "@/components/global/SeForm.vue";
import SeCountryDropdown from "@/components/global/SeCountryDropdown.vue";
import SeIllustration from "@/components/global/SeIllustration.vue";
import PostponedTaskRow from "@/components/tasks/table/PostponedTaskRow.vue";
import SeSnackbar from "@/components/global/snackbars/SeSnackbar.vue";
import { setupCalendar, Calendar, DatePicker } from "v-calendar";
import SeProgressBar from "@/components/global/SeProgressBar.vue";
import "v-calendar/style.css";
import StockImagePicker from "@/components/global/StockImagePicker.vue";
import SePlaceholder from "@/components/global/SePlaceholder.vue";
import SeDropdown from "@/components/global/dropdown/SeDropdown.vue";
import SeDropdownItem from "@/components/global/dropdown/SeDropdownItem.vue";
import SeColorPicker from "@/components/global/SeColorPicker.vue";
import SeChip from "@/components/global/chip/SeChip.vue";
import SeChipList from "@/components/global/chip/SeChipList.vue";
import SeNumberPicker from "@/components/global/SeNumberPicker.vue";
import SeRangePickerModal from "@/components/global/SeRangePickerModal.vue";
import * as Firebase from "@/firebase/Firebase";
import ProfilingChart from "./components/home/ProfilingChart.vue";
import SeEditableText from "./components/global/SeEditableText.vue";
import {
  Chart,
  Filler,
  TimeScale,
  BarElement,
  BarController,
  CategoryScale,
} from "chart.js";

import Annotation from "chartjs-plugin-annotation";
import zoomPlugin from "chartjs-plugin-zoom";
import "@cyhnkckali/vue3-color-picker/dist/style.css";
import SeCheckboxGroup from "./components/global/SeCheckboxGroup.vue";

Chart.register(zoomPlugin);
Chart.register(Filler);
Chart.register(Annotation);
Chart.register(TimeScale);
Chart.register(BarElement);
Chart.register(BarController);
Chart.register(CategoryScale);

const vueFire = require("vuefire");

const app = createApp({
  render: () => h(App),
});

Firebase.initalize();

let firebaseApp = Firebase.firebase;

app.use(vueFire.VueFire, {
  firebaseApp,
});

app.use(createPinia());
app.use(router);
app.component("VCalendar", Calendar);
app.component("VDatePicker", DatePicker);
app.component("SeButton", SeButton);
app.component("SeFadeButton", SeFadeButton);
app.component("SeInput", SeInput);
app.component("ToggleSwitch", ToggleSwitch);
app.component("SeMultiFilter", SeMultiFilter);
app.component("SeSimpleTab", SeSimpleTab);
app.component("SeTab", SeTab);
app.component("SeTextarea", SeTextarea);
app.component("SeImage", SeImage);
app.component("SeCheckBox", SeCheckBox);
app.component("LockedField", LockedField);
app.component("SeSnackbar", SeSnackbar);
app.component("SePlaceholder", SePlaceholder);
app.component("ReadonlyField", ReadonlyField);
app.component("SeDropdownFilter", SeDropdownFilter);
app.component("SeLoadingIndicator", SeLoadingIndicator);
app.component("HalfCircleSpinner", HalfCircleSpinner);
app.component("SeIconDropdown", SeIconDropdown);
app.component("SeDropdown", SeDropdown);
app.component("SeDropdownItem", SeDropdownItem);
app.component("ToggleIcon", ToggleIcon);
app.component("SeProgressBar", SeProgressBar);
app.component("WideCard", WideCard);
app.component("SeForm", SeForm);
app.component("SeCountryDropdown", SeCountryDropdown);
app.component("SeColorPicker", SeColorPicker);
app.component("SeModal", SeModal);
app.component("SeInputModal", SeInputModal);
app.component("SeDeleteConfirmModal", SeDeleteConfirmModal);
app.component("SearchBar", SearchBar);
app.component("NavHeader", NavHeader);
app.component("SeRadio", SeRadio);
app.component("ModuleSearchResult", ModuleSearchResult);
app.component("SeTable", SeTable);
app.component("SeExpendable", SeExpendable);
app.component("SeCalendarRanged", SeCalendarRanged);
app.component("SeEditableText", SeEditableText);
app.component("SeInputFile", SeInputFile);

app.component("SeVerticalTab", SeVerticalTab);

app.component("SeIllustration", SeIllustration);

app.component("PostponedTaskRow", PostponedTaskRow);

app.component("StockImagePicker", StockImagePicker);

app.component("SeNumberPicker", SeNumberPicker);

app.component("SeRangePickerModal", SeRangePickerModal);

app.component("ProfilingChart", ProfilingChart);

app.component("SeChipList", SeChipList);

app.component("SeChip", SeChip);

app.component("SeCheckboxGroup", SeCheckboxGroup);

app.mount("#app");
