
import { defineComponent } from "@vue/runtime-core";
import * as Device from "@/utils/Device";
import * as DateUtils from "@/utils/DateUtils";
import * as Modals from "@/utils/Modals";
import { getAttachmentManager } from "@/firebase/AttachmentManager";

export default defineComponent({
  props: ["result"],

  data() {
    return {
      DateUtils: DateUtils,
    };
  },
  methods: {
    displayPictures() {
      Modals.openGallery(
        this.result.pictures.map((x) => x.url),
        this.onImageError
      );
    },
    async onImageError(src: string) {
      var res = this.result.pictures.find((x) => x.url == src);
      let newUrl = await getAttachmentManager().getDownloadUrl(
        res.firebase_path
      );

      res.url = newUrl;
      Modals.openGallery(
        this.result.pictures.map((x) => x.url),
        this.onImageError
      );
    },
  },
});
