import { DocumentReference } from "firebase/firestore";
import TaskModuleConfiguration from "./TaskModuleConfiguration";

export default class TaskModuleConfigurationTemperature extends TaskModuleConfiguration {
  public clone() {
    return new TaskModuleConfigurationTemperature(
      this.temperature_elements.map((x) => x),
      this.is_delivery,
      this.automatic
    );
  }
  temperature_elements: DocumentReference[];
  is_delivery: boolean;
  automatic: boolean;

  constructor(
    temperature_elements: DocumentReference[],
    is_delivery: boolean,
    automatic: boolean
  ) {
    super();
    this.temperature_elements = temperature_elements;
    this.is_delivery = is_delivery;
    this.automatic = automatic;
  }

  public static fromFirebase(x: any): TaskModuleConfigurationTemperature {
    return new TaskModuleConfigurationTemperature(
      x.temperature_elements == null ? [] : x.temperature_elements,
      x.is_delivery == undefined ? false : x.is_delivery,
      x.automatic == undefined ? false : x.automatic
    );
  }
  public override toFirebase(): any {
    return {
      temperature_elements: this.temperature_elements,
      is_delivery: this.is_delivery,
      automatic: this.automatic,
    };
  }
}
