
import { defineComponent } from "@vue/runtime-core";
import * as TaskManager from "@/firebase/TaskManager";
import * as Device from "@/utils/Device";
import * as Modals from "@/utils/Modals";
import { getAttachmentManager } from "@/firebase/AttachmentManager";
export default defineComponent({
  props: ["result"],
  data() {
    return {
      employeeName: "",
    };
  },
  methods: {
    displayPicture() {
      Modals.openGallery([this.result.url], this.onImageError);
    },
    async onImageError(src: string) {
      var res = this.result.pictures.find((x) => x.url == src);
      let newUrl = await getAttachmentManager().getDownloadUrl(
        res.firebase_path
      );

      res.url = newUrl;
      var carousel = document.body.querySelector(".se-carousel");
      carousel.parentNode.removeChild(carousel);
      Modals.openGallery([this.result.picture.url], this.onImageError);
    },
  },
  async mounted() {
    if (this.result.employee != null) {
      let employee = await TaskManager.getEmployee(this.result.employee);
      this.employeeName = employee.name + " " + employee.surname;
    }
  },
});
