import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "flex col g30 grow",
  style: {"width":"550px"}
}
const _hoisted_2 = { class: "flex center justify-between" }
const _hoisted_3 = { class: "flex g24" }
const _hoisted_4 = {
  class: "items-list grid",
  id: "auditingGrid"
}
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = { class: "text-muted no-mobile" }
const _hoisted_7 = { class: "text-muted no-mobile" }
const _hoisted_8 = { class: "item-icon-container" }
const _hoisted_9 = {
  key: 0,
  class: "item-icon-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeFadeButton = _resolveComponent("SeFadeButton")!
  const _component_SeModal = _resolveComponent("SeModal")!
  const _component_WideCard = _resolveComponent("WideCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SeModal, {
      title: _ctx.Lang.getI18N('results'),
      ref: "resultsModal"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (result, index) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", null, _toDisplayString(result.getEndDateFormat()), 1)
              ]),
              _createVNode(_component_SeFadeButton, {
                icon: "/ico/download.svg",
                iconRight: true,
                class: "g10",
                style: {"width":"fit-content"},
                onClick: ($event: any) => (_ctx.openPDF(result))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.Lang.getI18N("download-pdf")), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]))
          }), 256))
        ])
      ]),
      _: 1
    }, 8, ["title"]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.audits, (audit) => {
        return _withDirectives((_openBlock(), _createBlock(_component_WideCard, {
          key: audit.ref!.id,
          icon: "/ico/auditing-blue.svg",
          title: audit.name,
          obj: audit,
          active: audit.active,
          isHighlighted: false
        }, {
          subtitle: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.Lang.getI18N("sections")) + ": " + _toDisplayString(audit.getSectionsAmount()), 1),
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.Lang.getI18N("questions")) + ": " + _toDisplayString(audit.calculateQuestionsAmount()), 1)
            ])
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_SeFadeButton, {
                icon: "/ico/edit-blue.svg",
                iconRight: true,
                iconSize: 27,
                onClick: ($event: any) => (_ctx.openAuditDetails(audit))
              }, null, 8, ["onClick"])
            ]),
            (_ctx.isManager())
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_SeFadeButton, {
                    icon: "/ico/task.svg",
                    iconRight: true,
                    iconSize: 27,
                    onClick: ($event: any) => (_ctx.openAuditResults(audit))
                  }, null, 8, ["onClick"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["title", "obj", "active"])), [
          [_vShow, audit.active || _ctx.auditsState.displayUnactive]
        ])
      }), 128))
    ])
  ], 64))
}