import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  addDoc,
  doc,
  documentId,
  updateDoc,
  setDoc,
  arrayUnion,
  orderBy,
  limitToLast,
  DocumentReference,
  limit,
  runTransaction,
  writeBatch,
  Query,
  DocumentData,
} from "firebase/firestore";

import { getState } from "../pinia/AppState";

import Store from "../model/Store";
import User from "../model/User";
import Room from "../model/Room";
import { Role } from "../model/User";
import Probe from "@/model/Probe";
import Employee from "@/model/Employee";
import Task from "@/model/Task";
import { getElementManager } from "@/firebase/ElementManager";
import * as TaskManager from "@/firebase/TaskManager";
import Database from "@/model/Database";
import StoreElement from "@/model/Elements/StoreElement";
import StoreLabel from "@/model/Labels/StoreLabel";
import StoreTaskConfiguration from "@/model/Tasks/StoreTaskConfiguration";
import * as LabelsManager from "@/firebase/LabelsManager";
import ElementType from "@/model/ElementType";
import { generateUniqueId } from "@/model/utils/uniqueIdGenerator";
import { formatDate } from "@/utils/DateUtils";
import { downloadFile } from "@/utils/DOMUtils";
import TaskConfiguration from "@/model/Tasks/TaskConfiguration";
import * as ElementTypeManager from "@/firebase/ElementTypeManager";
import Element from "@/model/Elements/Element";
import * as Firebase from "@/firebase/Firebase";
import { getTaskConfigurationManager } from "@/firebase/TaskConfigurationManager";
import { SnapshotListener } from "@/utils/SnapshotListener";
import { defineStore } from "pinia";
import { getDatabaseManager } from "./DatabaseManager";

interface ImportedData {
  database: string | undefined;
  elementTypes: ElementType[];
  elements: StoreElement[];
  labels: StoreLabel[];
  tasks: StoreTaskConfiguration[];
  metadata: {
    export_date: String;
    database_name: String;
    database_reference: String;
    store_reference: String;
    store_name: String;
    user_email: String;
    user_uuid: String;
    dashboard_version: String;
  };
}

interface StoreManager {
  listener: SnapshotListener<Store> | null;
}

export const getStoreManager = defineStore("StoreManager", {
  state: (): StoreManager => {
    return {
      listener: null,
    };
  },
  actions: {
    async initialize() {
      if (this.listener != null) {
        return;
      }

      let user = getState().userData!;

      if (user == null) {
        console.log("Unable to initialize store manager. User is null");
        return;
      }

      let storeQuery: Query<DocumentData> | null = null;

      if (user.role == Role.Distributor) {
        let distributorRef = user.distributor;

        storeQuery = query(
          collection(Firebase.firestore, "stores"),
          where("distributor", "==", distributorRef),
          orderBy("name")
        );
      } else if (user.role == Role.Admin) {
        storeQuery = query(
          collection(Firebase.firestore, "stores"),
          orderBy("name")
        );
      } else if (user.role == Role.Manager) {
        await getDatabaseManager().initialize();

        var databases = getDatabaseManager().getDatabases();

        if (databases.length == 0) {
          storeQuery = null;
        } else {
          storeQuery = query(
            collection(Firebase.firestore, "stores"),
            where(
              "database",
              "in",
              databases.map((x) => x.ref)
            )
          );
        }
      } else {
        if (user.stores.length > 0) {
          storeQuery = query(
            collection(Firebase.firestore, "stores"),
            where(
              documentId(),
              "in",
              user.stores.map((storeRef: DocumentReference) => storeRef.id)
            )
          );
        }
      }

      let listener = new SnapshotListener<Store>(
        Store.fromFirestore,
        storeQuery
      );

      this.listener = listener;
      await this.listener.ensureInit();
    },

    async getStore(id: string) {
      let cached = this.getStoreFromCacheOnly(id);

      if (cached != null) {
        return cached;
      }

      const docRef = doc(Firebase.firestore, "stores", id);
      const snapshot = await getDoc(docRef);
      let store: Store = Store.fromFirestore(snapshot);
      return store;
    },
    getStoreFromCacheOnly(id: string) {
      let cached = this.getStores(false).find((x) => x.ref?.id == id);

      if (cached) {
        return cached;
      } else {
        return null;
      }
    },
    async getStoresFromDatabase(database: Database) {
      return this.getStores(true).filter(
        (x) => x.database!.id == database.ref.id
      );
    },
    async getStoresOfUser(user: User) {
      if (
        user.stores.length == 0 &&
        user.role != Role.Distributor &&
        user.role != Role.Admin
      ) {
        return [];
      }

      let storeQuery: Query<DocumentData> | null = null;

      if (user.role == Role.Distributor) {
        let distributorRef = user.distributor;

        storeQuery = query(
          collection(Firebase.firestore, "stores"),
          where("distributor", "==", distributorRef),
          orderBy("name")
        );
      } else if (user.role == Role.Admin) {
        storeQuery = query(
          collection(Firebase.firestore, "stores"),
          orderBy("name")
        );
      } else {
        if (user.stores.length == 0) {
          return [];
        }
        storeQuery = query(
          collection(Firebase.firestore, "stores"),
          where(
            documentId(),
            "in",
            user.stores.map((storeRef: DocumentReference) => storeRef.id)
          )
        );
      }
      let snapshot = await getDocs(storeQuery);

      return snapshot.docs.map((x) => Store.fromFirestore(x));
    },
    getAvailableStoreOnHistory() {
      var stores: Store[] = this.getStores(true);

      stores = stores.filter((x) => x.active && x.secureat_licence);

      let user = getState().userData!;
      if (user.role == Role.Manager) {
        var databaseManager = getDatabaseManager();
        var results = [];

        for (let store of stores) {
          var db = databaseManager.getDatabaseByReferenceFromCache(
            store.database!
          );

          if (db!.store_access == true) {
            results.push(store);
          }
        }
        return results;
      } else {
        return stores;
      }
    },
    getStores(mustHaveDatabase: boolean): Store[] {
      let user = getState().userData!;

      if (user == null) {
        return [];
      }

      return mustHaveDatabase
        ? this.listener?.items.filter((x) => x.database != null)!
        : this.listener?.items!;
    },

    async readFileContents(file: File): Promise<string> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          const content = event.target?.result as string;
          resolve(content);
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsText(file);
      });
    },
  },
});
