import Quiz from "../Communication/Quiz";
import Attachment from "../Filesystem/Attachment";

export default class TrainingSection {
  title: string;
  quiz: Quiz | null;
  attachment: Attachment | null;
  editMode = false;

  constructor(title: string, quiz: Quiz | null, attachment: Attachment | null) {
    this.title = title;
    this.quiz = quiz;
    this.attachment = attachment;
  }

  public static fromFirestore(x: any) {
    return new TrainingSection(
      x.title,
      x.quiz ? Quiz.fromFirestore(x.quiz) : null,
      x.attachment ? Attachment.fromFirestore(x.attachment) : null
    );
  }
  toFirestore() {
    return {
      title: this.title,
      quiz: this.quiz ? this.quiz.toFirestore() : null,
      attachment: this.attachment ? this.attachment.toFirestore() : null,
    };
  }
  clone(): TrainingSection {
    return new TrainingSection(
      this.title,
      this.quiz ? this.quiz.clone() : null,
      this.attachment ? this.attachment.clone() : null
    );
  }
}
