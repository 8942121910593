import {
  collection,
  query,
  where,
  doc,
  getDocs,
  getDoc,
  addDoc,
  documentId,
  updateDoc,
  DocumentReference,
  orderBy,
  WriteBatch,
} from "firebase/firestore";

import { getFunctions, httpsCallable } from "firebase/functions";

import Element from "@/model/Elements/Element";
import DatabaseLabel from "@/model/Labels/DatabaseLabel";
import * as Firebase from "@/firebase/Firebase";
import * as Storage from "./Storage";
import * as DatabaseManager from "./DatabaseManager";

import { getState } from "../pinia/AppState";
import ElementType from "@/model/ElementType";
import DatabaseElement from "@/model/Elements/DatabaseElement";
import StoreElement from "@/model/Elements/StoreElement";
import Store from "@/model/Store";
import { ref } from "firebase/storage";
import Label from "@/model/Labels/Label";

import StoreLabel from "@/model/Labels/StoreLabel";

export async function getStoreLabels(store: Store): Promise<StoreLabel[]> {
  let results: StoreLabel[] = [] as StoreLabel[];
  let state = getState();

  const q = query(
    collection(Firebase.firestore, "/stores/" + store.ref!.id + "/labels/")
  );

  let snapshot = await getDocs(q);

  let storeLabels = snapshot.docs.map((x) => StoreLabel.fromFirestore(x));

  for (let storeLabel of storeLabels) {
    if (storeLabel.origin != null) {
      let originValue = await getDatabaseLabelByReference(storeLabel.origin);
      storeLabel.setOriginValue(originValue);
    }
    results.push(storeLabel);
  }

  return results;
}

export async function getStoreLabelsByElement(
  store: Store,
  element: StoreElement
): Promise<StoreLabel[]> {
  let results: StoreLabel[] = [] as StoreLabel[];

  const q = query(
    collection(Firebase.firestore, "/stores/" + store.ref!.id + "/labels/"),
    where("element", "==", element.ref!)
  );

  let snapshot = await getDocs(q);

  let storeLabels = snapshot.docs.map((x) => StoreLabel.fromFirestore(x));

  for (let storeLabel of storeLabels) {
    if (storeLabel.origin != null) {
      let originValue = await getDatabaseLabelByReference(storeLabel.origin);
      storeLabel.setOriginValue(originValue);
    } else {
      if (element.originValue != null && element.originValue.labels_lock)
        continue;
    }
    results.push(storeLabel);
  }

  let databaseLabels = await getDatabaseLabelsByElement(
    store.database?.id!,
    element
  );

  for (let databaseLabel of databaseLabels) {
    if (
      !results.some(
        (x) => x.origin != null && x.origin.id! == databaseLabel.ref!.id!
      )
    ) {
      results.push(StoreLabel.fromOrigin(databaseLabel, store));
    }
  }

  return results.sort((a, b) => a.index! - b.index!);
}

export async function getDatabaseLabelByReference(
  ref: DocumentReference
): Promise<DatabaseLabel> {
  const snapshot = await getDoc(ref);
  let databaseLabel: DatabaseLabel = DatabaseLabel.fromFirestore(snapshot);
  return databaseLabel;
}
export async function getDatabaseLabelsByElement(
  databaseId: string,
  element: Element
): Promise<DatabaseLabel[]> {
  let ref = null;
  if (element instanceof StoreElement) {
    if (element.origin == null) {
      return [];
    }
    ref = element.origin;
  } else {
    ref = element.ref;
  }

  const q = query(
    collection(Firebase.firestore, "/databases/" + databaseId + "/labels/"),
    where("element", "==", ref!)
  );

  let snapshot = await getDocs(q);

  var results = snapshot.docs.map((x) => DatabaseLabel.fromFirestore(x));

  return results.sort((a, b) => a.index! - b.index!);
}

export async function setLabels(element: Element, labels: Label[]) {
  let index = 0;

  for (let label of labels) {
    label.index = index;
    label.element = element.ref;
    await label.set();
    index++;
  }
}
