import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-27b49bb2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex col" }
const _hoisted_2 = { class: "flex align-center grow justify-between" }
const _hoisted_3 = { class: "flex align-center" }
const _hoisted_4 = { class: "bold" }
const _hoisted_5 = { class: "grow flex align-end justify-end" }
const _hoisted_6 = {
  class: "flex",
  style: {"align-items":"stretch"}
}
const _hoisted_7 = {
  class: "flex col p12",
  style: {"flex":"2 0 0","background-color":"#f3fafa","border-radius":"12px"}
}
const _hoisted_8 = { class: "flex align-center" }
const _hoisted_9 = {
  class: "grid flex col tag-panel",
  id: "storeGrid"
}
const _hoisted_10 = { class: "flex align-center" }
const _hoisted_11 = { clas: "flex col g24" }
const _hoisted_12 = { class: "flex col p12 tag-panel" }
const _hoisted_13 = {
  class: "grid",
  id: "groupGrid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeEditableText = _resolveComponent("SeEditableText")!
  const _component_SeFadeButton = _resolveComponent("SeFadeButton")!
  const _component_SeDropdownItem = _resolveComponent("SeDropdownItem")!
  const _component_SeDropdown = _resolveComponent("SeDropdown")!
  const _component_SeButton = _resolveComponent("SeButton")!
  const _component_SearchBar = _resolveComponent("SearchBar")!
  const _component_SeCheckboxGroup = _resolveComponent("SeCheckboxGroup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SeDropdown, {
        onSelectionChanged: _ctx.onTagChange,
        ref: "tagDropdown",
        class: "grow",
        titleFunc: (tag) => tag.name
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.tags.filter((x) => x.active), (tag) => {
            return (_openBlock(), _createBlock(_component_SeDropdownItem, {
              displayTitle: false,
              value: tag,
              key: tag.ref.id
            }, {
              heading: _withCtx(() => [
                _createVNode(_component_SeEditableText, {
                  onClick: ($event: any) => (tag.editMode ? $event.stopPropagation() : null),
                  edit: tag.editMode,
                  modelValue: tag.name,
                  "onUpdate:modelValue": ($event: any) => ((tag.name) = $event)
                }, null, 8, ["onClick", "edit", "modelValue", "onUpdate:modelValue"])
              ]),
              tailing: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_SeFadeButton, {
                    onClick: [
                      _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
                      ($event: any) => (tag.editMode = !tag.editMode)
                    ],
                    icon: "ico/edit-blue.svg"
                  }, null, 8, ["onClick"]),
                  _createVNode(_component_SeFadeButton, {
                    onClick: [
                      _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
                      ($event: any) => (_ctx.removeTag(tag))
                    ],
                    icon: "ico/trash.svg"
                  }, null, 8, ["onClick"])
                ])
              ]),
              _: 2
            }, 1032, ["value"]))
          }), 128)),
          _createVNode(_component_SeDropdownItem, {
            displayTitle: false,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.addTag())),
            interact: false,
            class: "noselect"
          }, {
            heading: _withCtx(() => [
              _createElementVNode("span", _hoisted_4, "+ " + _toDisplayString(_ctx.Lang.getI18N("add")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onSelectionChanged", "titleFunc"]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_SeButton, {
          ref: "tagBtn",
          onClick: _ctx.saveTags,
          tooltip: "Save",
          icon: "/ico/save.svg"
        }, null, 8, ["onClick"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_SearchBar, {
            class: "grow",
            searchChange: _ctx.onSearchChange,
            calm: true
          }, null, 8, ["searchChange"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_SeCheckboxGroup, {
            ref: "storesWithoutTag",
            items: _ctx.displayedStoreWithoutTags,
            labelFunction: (store) => store.name
          }, null, 8, ["items", "labelFunction"])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_SeFadeButton, {
            onClick: _ctx.addStores,
            icon: "/ico/arrow-left.svg"
          }, null, 8, ["onClick"]),
          _createVNode(_component_SeFadeButton, {
            onClick: _ctx.removeStores,
            icon: "/ico/arrow-right.svg"
          }, null, 8, ["onClick"])
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_SeCheckboxGroup, {
            ref: "storesWithTag",
            items: _ctx.storesWithTag,
            labelFunction: (store) => store.name
          }, null, 8, ["items", "labelFunction"])
        ])
      ])
    ])
  ]))
}