import {
  DocumentSnapshot,
  DocumentReference,
  doc,
  collection,
} from "firebase/firestore";
import SecureatSnapshot from "../SecureatSnapshot";
import Element from "./Element";
import * as ImageKit from "@/utils/ImageUtils";
import { AllergenEnum } from "../AllergensEnum";
import Attachment from "../Filesystem/Attachment";
import * as Firebase from "@/firebase/Firebase";

export default class DatabaseElement extends Element {
  public active: boolean;
  public illustration_path: string | null;
  public illustration_url: string | null;
  public labels_module_active: boolean;
  public maximum_temperature: number | null;
  public minimum_temperature: number | null;
  public name: string;
  public temperature_picture: boolean;
  public temperature_delivery_item: boolean | null;
  public temperatures_module_active: boolean;
  public traceability_module_active: boolean;
  public type: DocumentReference | null;
  public oils_module_active: boolean = false;
  public maximum_polarity: number | null = 0;
  public reheating_temperature: number | null = null;
  public cooling_temperature: number | null = null;
  public allergens: AllergenEnum[] | null = null;
  public attachments: Attachment[] = [];
  public oils_require_picture: boolean = false;

  active_lock: boolean;
  illustration_lock: boolean;
  labels_module_active_lock: boolean;
  maximum_temperature_lock: boolean;
  minimum_temperature_lock: boolean;
  name_lock: boolean;
  temperature_picture_lock: boolean;
  temperature_delivery_item_lock: boolean;
  temperatures_module_active_lock: boolean;
  traceability_module_active_lock: boolean;
  type_lock: boolean;
  oils_module_active_lock: boolean;
  maximum_polarity_lock: boolean;
  labels_lock: boolean;
  temperature_control_module_active_lock: boolean;
  temperature_control_module_active: boolean;
  reheating_temperature_lock: boolean;
  cooling_temperature_lock: boolean;
  allergens_lock: boolean;
  attachments_lock: boolean;
  require_oils_picture_lock: boolean = true;

  constructor(
    ref: DocumentReference,
    active: boolean = true,
    illustration_path: string | null = null,
    illustration_url: string | null = null,
    labels_module_active: boolean = false,
    maximum_temperature: number | null = 0,
    minimum_temperature: number | null = 0,
    name: string = "",
    temperature_picture: boolean = false,
    temperature_delivery_item: boolean | null = false,
    temperatures_module_active: boolean = false,
    traceability_module_active: boolean = false,
    type: DocumentReference | null = null,
    oils_module_active: boolean = false,
    maximum_polarity: number | null = 25,
    oils_require_picture: boolean = false,
    active_lock: boolean = true,
    illustration_lock: boolean = true,
    labels_module_active_lock: boolean = true,
    maximum_temperature_lock: boolean = true,
    minimum_temperature_lock: boolean = true,
    name_lock: boolean = true,
    temperature_picture_lock: boolean = true,
    temperature_delivery_item_lock: boolean = true,
    temperatures_module_active_lock: boolean = true,
    traceability_module_active_lock: boolean = true,
    type_lock: boolean = true,
    oils_module_active_lock: boolean = true,
    maximum_polarity_lock: boolean = true,
    labels_lock: boolean = true,
    temperature_control_module_active_lock: boolean = true,
    temperature_control_module_active: boolean = false,
    reheating_temperature: number | null = null,
    reheating_temperature_lock: boolean = true,
    cooling_temperature: number | null = null,
    cooling_temperature_lock: boolean = true,
    allergens: AllergenEnum[] | null = null,
    allergens_lock: boolean = true,
    attachments: Attachment[] = [],
    attachments_lock: boolean = true,
    require_oils_picture_lock: boolean = true
  ) {
    super(ref);

    this.active_lock = active_lock;
    this.illustration_lock = illustration_lock;
    this.labels_module_active_lock = labels_module_active_lock;
    this.maximum_temperature_lock = maximum_temperature_lock;
    this.minimum_temperature_lock = minimum_temperature_lock;
    this.name_lock = name_lock;
    this.temperature_picture_lock = temperature_picture_lock;
    this.temperature_delivery_item_lock = temperature_delivery_item_lock;
    this.temperatures_module_active_lock = temperatures_module_active_lock;
    this.traceability_module_active_lock = traceability_module_active_lock;
    this.type_lock = type_lock;
    this.oils_module_active_lock = oils_module_active_lock;
    this.maximum_polarity_lock = maximum_polarity_lock;
    this.labels_lock = labels_lock;
    this.temperature_control_module_active_lock =
      temperature_control_module_active_lock;
    this.active = active;
    this.illustration_path = illustration_path;
    this.illustration_url = illustration_url;
    this.labels_module_active = labels_module_active;
    this.maximum_temperature = maximum_temperature;
    this.minimum_temperature = minimum_temperature;
    this.name = name;
    this.temperature_picture = temperature_picture;
    this.temperature_delivery_item = temperature_delivery_item;
    this.temperatures_module_active = temperatures_module_active;
    this.traceability_module_active = traceability_module_active;
    this.type = type;
    this.oils_module_active = oils_module_active;
    this.maximum_polarity = maximum_polarity;
    this.temperature_control_module_active = temperature_control_module_active;
    this.reheating_temperature = reheating_temperature;
    this.reheating_temperature_lock = reheating_temperature_lock;
    this.cooling_temperature = cooling_temperature;
    this.cooling_temperature_lock = cooling_temperature_lock;
    this.allergens = allergens;
    this.allergens_lock = allergens_lock;
    this.attachments = attachments;
    this.attachments_lock = attachments_lock;
    this.require_oils_picture_lock = require_oils_picture_lock;
    this.oils_require_picture = oils_require_picture;
  }

  public static fromFirestore(snapshot: DocumentSnapshot): DatabaseElement {
    const data = snapshot.data()!;

    let result = new DatabaseElement(
      snapshot.ref,
      SecureatSnapshot.getValue<boolean>(data, "active"),
      SecureatSnapshot.getNullableValue<string>(
        data,
        "illustration_path",
        String
      ),
      SecureatSnapshot.getNullableValue<string>(
        data,
        "illustration_url",
        String
      ),
      SecureatSnapshot.getValue<boolean>(data, "labels_module_active"),
      SecureatSnapshot.getNullableValue<number>(
        data,
        "maximum_temperature",
        Number
      ),
      SecureatSnapshot.getNullableValue<number>(
        data,
        "minimum_temperature",
        Number
      ),
      SecureatSnapshot.getValue<string>(data, "name", ""),
      SecureatSnapshot.getValue<boolean>(data, "temperature_picture"),
      SecureatSnapshot.getValue<boolean>(
        data,
        "temperature_delivery_item",
        false
      ),

      SecureatSnapshot.getValue<boolean>(data, "temperatures_module_active"),
      SecureatSnapshot.getValue<boolean>(data, "traceability_module_active"),
      SecureatSnapshot.getNullableValue<DocumentReference>(
        data,
        "type",
        DocumentReference
      ),
      SecureatSnapshot.getValue<boolean>(data, "oils_module_active"),
      SecureatSnapshot.getNullableValue<number>(
        data,
        "maximum_polarity",
        Number
      ),
      SecureatSnapshot.getNullableValue<boolean>(
        data,
        "oils_require_picture",
        Boolean
      ),
      SecureatSnapshot.getValue<boolean>(data, "active_lock"),
      SecureatSnapshot.getValue<boolean>(data, "illustration_lock"),
      SecureatSnapshot.getValue<boolean>(data, "labels_module_active_lock"),
      SecureatSnapshot.getValue<boolean>(data, "maximum_temperature_lock"),
      SecureatSnapshot.getValue<boolean>(data, "minimum_temperature_lock"),
      SecureatSnapshot.getValue<boolean>(data, "name_lock"),
      SecureatSnapshot.getValue<boolean>(data, "temperature_picture_lock"),
      SecureatSnapshot.getValue<boolean>(
        data,
        "temperature_delivery_item_lock",
        true
      ),
      SecureatSnapshot.getValue<boolean>(
        data,
        "temperatures_module_active_lock"
      ),
      SecureatSnapshot.getValue<boolean>(
        data,
        "traceability_module_active_lock"
      ),
      SecureatSnapshot.getValue<boolean>(data, "type_lock"),
      SecureatSnapshot.getValue<boolean>(data, "oils_module_active_lock"),
      SecureatSnapshot.getValue<boolean>(data, "maximum_polarity_lock"),
      SecureatSnapshot.getValue<boolean>(data, "labels_lock"),
      SecureatSnapshot.getValue<boolean>(
        data,
        "temperature_control_module_active_lock",
        true
      ),

      SecureatSnapshot.getValue<boolean>(
        data,
        "temperature_control_module_active",
        false
      ),
      SecureatSnapshot.getNullableValue<number>(
        data,
        "reheating_temperature",
        Number
      ),
      SecureatSnapshot.getValue<boolean>(
        data,
        "reheating_temperature_lock",
        true
      ),
      SecureatSnapshot.getNullableValue<number>(
        data,
        "cooling_temperature",
        Number
      ),
      SecureatSnapshot.getValue<boolean>(
        data,
        "cooling_temperature_lock",
        true
      ),
      SecureatSnapshot.getValue<[]>(data, "allergens", []),
      SecureatSnapshot.getValue<boolean>(data, "allergens_lock", true),
      data.attachments == undefined || data.attachments == null
        ? []
        : data.attachments.map((x: any) => Attachment.fromFirestore(x)),
      SecureatSnapshot.getValue<boolean>(data, "attachments_lock", true)
    );

    result.reheating_temperature =
      result.reheating_temperature == null ? 0 : result.reheating_temperature;
    result.cooling_temperature =
      result.cooling_temperature == null ? 0 : result.cooling_temperature;

    if (result.maximum_polarity == null) {
      result.maximum_polarity = 25;
    }

    return result;
  }
  public toFirestore(): any {
    return {
      active_lock: this.active_lock,
      illustration_lock: this.illustration_lock,
      labels_module_active_lock: this.labels_module_active_lock,
      maximum_temperature_lock: this.maximum_temperature_lock,
      minimum_temperature_lock: this.minimum_temperature_lock,
      name_lock: this.name_lock,
      temperature_picture_lock: this.temperature_picture_lock,
      temperature_delivery_item_lock: this.temperature_delivery_item_lock,
      temperatures_module_active_lock: this.temperatures_module_active_lock,
      traceability_module_active_lock: this.traceability_module_active_lock,
      type_lock: this.type_lock,
      oils_require_picture: this.oils_require_picture,
      require_oils_picture_lock: this.require_oils_picture_lock,
      active: this.active,
      illustration_path: this.illustration_path,
      illustration_url: this.illustration_url,
      labels_module_active: this.labels_module_active,
      maximum_temperature: this.maximum_temperature,
      minimum_temperature: this.minimum_temperature,
      name: this.name,
      temperature_picture: this.temperature_picture,
      temperature_delivery_item: this.temperature_delivery_item,
      temperatures_module_active: this.temperatures_module_active,
      traceability_module_active: this.traceability_module_active,
      type: this.type,
      oils_module_active_lock: this.oils_module_active_lock,
      maximum_polarity_lock: this.maximum_polarity_lock,
      oils_module_active: this.oils_module_active,
      maximum_polarity: this.maximum_polarity,
      temperature_control_module_active_lock:
        this.temperature_control_module_active_lock,
      temperature_control_module_active: this.temperature_control_module_active,
      labels_lock: this.labels_lock,
      reheating_temperature: this.reheating_temperature,
      reheating_temperature_lock: this.reheating_temperature_lock,
      cooling_temperature: this.cooling_temperature,
      cooling_temperature_lock: this.cooling_temperature_lock,
      allergens: this.allergens,
      allergens_lock: this.allergens_lock,
      attachments:
        this.attachments == null
          ? []
          : this.attachments?.map((x) => x.toFirestore()),
      attachments_lock: this.attachments_lock,
    };
  }

  public override getActive() {
    return this.active;
  }
  public override getIllustrationPath() {
    return this.illustration_path;
  }
  public override getIllustrationUrl() {
    return this.illustration_url;
  }
  public override getLabelsModuleActive() {
    return this.labels_module_active;
  }
  public override getMaximumTemperature() {
    return this.maximum_temperature;
  }
  public override getMinimumTemperature() {
    return this.minimum_temperature;
  }
  public override getName() {
    return this.name;
  }
  public override getTemperaturePicture() {
    return this.temperature_picture;
  }
  public override getTemperatureDeliveryItem() {
    return this.temperature_delivery_item;
  }

  public override getTemperaturesModuleActive() {
    return this.temperatures_module_active;
  }
  public override getTraceabilityModuleActive() {
    return this.traceability_module_active;
  }

  public override getOilsModuleActive() {
    return this.oils_module_active;
  }

  public override getMaximumPolarity() {
    return this.maximum_polarity;
  }
  public override getType() {
    return this.type;
  }
  public getTemperatureControlModuleActive(): boolean | null {
    return this.temperature_control_module_active;
  }

  public getReheatingTemperature(): number | null {
    return this.reheating_temperature;
  }

  public getCoolingTemperature(): number | null {
    return this.cooling_temperature;
  }

  public getAllergens(): AllergenEnum[] | null {
    return this.allergens;
  }

  public getOilsRequirePicture(): boolean | null {
    return this.oils_require_picture;
  }
  public getAttachments() {
    return this.attachments;
  }
  static clone(
    el: DatabaseElement,
    cloneIllustration: boolean,
    cloneRef: boolean = false
  ) {
    return new DatabaseElement(
      cloneRef
        ? el.ref
        : doc(collection(Firebase.firestore, el.ref.parent.path)),
      el.active!,
      cloneIllustration ? el.illustration_path : null,
      cloneIllustration ? el.illustration_url : null,
      el.labels_module_active!,
      el.maximum_temperature!,
      el.minimum_temperature!,
      el.name!,
      el.temperature_picture,
      el.temperature_delivery_item,
      el.temperatures_module_active,
      el.traceability_module_active,
      el.type,
      el.oils_module_active,
      el.maximum_polarity,
      el.oils_require_picture,
      el.active_lock,
      el.illustration_lock,
      el.labels_module_active_lock,
      el.maximum_temperature_lock,
      el.minimum_temperature_lock,
      el.name_lock,
      el.temperature_picture_lock,
      el.temperature_delivery_item_lock,
      el.temperatures_module_active_lock,
      el.traceability_module_active_lock,
      el.type_lock,
      el.oils_module_active_lock,
      el.maximum_polarity_lock,
      el.labels_lock,
      el.temperature_control_module_active_lock,
      el.temperature_control_module_active,
      el.reheating_temperature,
      el.reheating_temperature_lock,
      el.cooling_temperature,
      el.cooling_temperature_lock,
      el.allergens,
      el.allergens_lock,
      el.attachments,
      el.attachments_lock,
      el.require_oils_picture_lock
    );
  }
}
