export enum ExtensionEnum {
  Documents = "documents",
  History = "history",
  Settings = "settings",
  Storage = "storage",
  Audit = "auditing",
  Training = "training",
  Shop = "shop",
  Employees = "employees",
}
