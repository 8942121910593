import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "grid grow",
  id: "communicationGrid"
}
const _hoisted_2 = { class: "flex justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommunicationStatus = _resolveComponent("CommunicationStatus")!
  const _component_SeFadeButton = _resolveComponent("SeFadeButton")!
  const _component_WideCard = _resolveComponent("WideCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.communications, (communication) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: communication.ref!.id
      }, [
        (communication.active || _ctx.state.displayUnactives)
          ? (_openBlock(), _createBlock(_component_WideCard, {
              key: 0,
              active: 
          communication.active == undefined ? false : communication.active
        ,
              icon: "/ico/communication-blue.svg",
              title: communication.name
            }, {
              subtitle: _withCtx(() => [
                _createVNode(_component_CommunicationStatus, { communication: communication }, null, 8, ["communication"])
              ]),
              footer: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  (_ctx.myRole != _ctx.Role.Customer)
                    ? (_openBlock(), _createBlock(_component_SeFadeButton, {
                        key: 0,
                        onClick: () => _ctx.editCommunication(communication),
                        icon: "/ico/edit-blue.svg"
                      }, null, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_SeFadeButton, {
                    onClick: () => _ctx.viewCommunicationStats(communication),
                    icon: "/ico/stats.svg"
                  }, null, 8, ["onClick"])
                ])
              ]),
              _: 2
            }, 1032, ["active", "title"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}