import { defineStore } from "pinia";
import Audit from "@/model/Auditing/Audit";
import { Result } from "@/model/Auditing/Result";
import { getAuditManager } from "@/firebase/AuditManager";
import Store from "@/model/Store";
import { getStoreManager } from "@/firebase/StoreManager";

interface AuditResultsState {
  audit: Audit | null;
  results: Result[];
  selectedResults: Result[] | null;
  stores: Store[] | null;
}

export const getAuditResultsState = defineStore("auditResultsState", {
  state: (): AuditResultsState => {
    return {
      audit: null,
      results: [],
      selectedResults: [],
      stores: [],
    };
  },
  actions: {
    async set(audit: Audit, selectedResults: Result[] | null = null) {
      const results = await getAuditManager().getResults(audit);

      let storeManager = getStoreManager();
      await storeManager.initialize();

      let stores = storeManager.getStores(false);
      this.stores = stores;

      this.results = results;
      this.audit = audit;
      if (this.results.length > 0) {
        if (selectedResults === null) {
          this.selectedResults = [];
        }
        this.audit.mergeResultsWithAudit(this.selectedResults!);
      }
    },
  },
});
