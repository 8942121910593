
import { defineComponent } from "@vue/runtime-core";
import SeDropdown from "./SeDropdown.vue";

export default defineComponent({
  props: {
    value: null,
    displayTitle: {
      type: Boolean,
      default: true,
      required: false,
    },
    defaultTitle: {
      type: String,
      default: "-",
      required: false,
    },
    interact: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  emits: ["selectionChanged"],

  methods: {
    onClick() {
      let parent = <any>this.$parent;

      if (this.interact) {
        parent.onSelectionChangedInternal(this.value);
      }
    },
    getTitle() {
      if (this.value == null) {
        return this.defaultTitle;
      }
      let parent = <any>this.$parent;
      return parent.titleFunc(this.value);
    },
  },
});
