
import { PropType, defineComponent, nextTick } from "vue";
import Database from "@/model/Database";
import * as Lang from "@/i18n/lang";
import Tag from "@/model/Tag";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { getStoreManager } from "@/firebase/StoreManager";
import Store from "@/model/Store";
import EmployeeRole from "@/model/EmployeeRole";
import SeDropdownItem from "../global/dropdown/SeDropdownItem.vue";
import { TrainingTargetType } from "./TrainingTargetType";
import { getTrainingState } from "@/pinia/trainings/TrainingState";

export default defineComponent({
  expose: ["applyFilters", "displayFilters"],

  data() {
    return {
      TrainingTargetType: TrainingTargetType,
      Lang: Lang,
      state: getTrainingState(),
      tags: [] as Tag[],
      stores: [] as Store[],
      targetType: TrainingTargetType.everyone,
    };
  },

  async mounted() {
    this.tags = await getDatabaseManager().getStoreTags(this.database);
    this.stores = await getStoreManager().getStoresFromDatabase(this.database);
  },
  props: {
    database: {
      type: Database,
      required: true,
    },
  },
  methods: {
    displayFilters() {
      var refs = <any>this.$refs;

      if (this.state.training!.target.stores.length > 0) {
        this.targetType = TrainingTargetType.store;

        var stores = this.state.training!.target.stores.map((x) =>
          this.stores.find((y) => y.ref.id == x.id)
        );

        nextTick(() => {
          refs.stores.setSelectedItems(stores);
        });
      } else if (this.state.training!.target.store_tags.length > 0) {
        this.targetType = TrainingTargetType.groups;

        var tags = this.state.target!.target.store_tags.map((x) =>
          this.tags.find((y) => y.ref.id == x.id)
        );

        nextTick(() => {
          refs.tags.setSelectedItems(tags);
        });
      }
    },
    applyFilters() {
      var refs = <any>this.$refs;

      this.state.training!.target.store_tags = [];
      this.state.training!.target.stores = [];

      if (this.targetType == TrainingTargetType.store) {
        var stores: Store[] = refs.stores.getSelectedItems();
        this.state.training!.target.stores = stores.map((x) => x.ref!);
      }
      if (this.targetType == TrainingTargetType.groups) {
        var tags: EmployeeRole[] = refs.tags.getSelectedItems();
        this.state.training!.target.store_tags = tags.map((x) => x.ref!);
      }
    },
  },
});
