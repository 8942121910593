import { DocumentSnapshot, DocumentReference } from "firebase/firestore";
import SecureatSnapshot from "../SecureatSnapshot";
import Attachment from "../Filesystem/Attachment";
import Quiz from "@/model/Communication/Quiz";
import * as DateUtils from "@/utils/DateUtils";
import CommunicationTarget from "../Communication/CommunicationTarget";
import TrainingSection from "./TrainingSection";
import { TrainingType } from "./TrainingType";

export default class Training extends SecureatSnapshot {
  active: boolean;
  user: DocumentReference;
  name: string;
  target: CommunicationTarget;
  type: TrainingType;
  sections: TrainingSection[];
  source: DocumentReference;
  lang: string;

  constructor(
    ref: DocumentReference,
    active: boolean,
    user: DocumentReference,
    name: string,
    target: CommunicationTarget,
    type: TrainingType,
    sections: TrainingSection[],
    source: DocumentReference,
    lang: string
  ) {
    super(ref);
    this.active = active;
    this.user = user;
    this.name = name;
    this.target = target;
    this.type = type;
    this.sections = sections;
    this.source = source;
    this.lang = lang;
  }

  public static fromFirestore(snapshot: DocumentSnapshot): Training {
    const data = snapshot.data()!;

    let result = new Training(
      snapshot.ref,
      data.active,
      data.user,
      data.name,
      CommunicationTarget.fromFirestore(data.target),
      data.type,
      data.sections != null && data.sections != undefined
        ? data.sections.map((x) => TrainingSection.fromFirestore(x))
        : [],
      data.source ? data.source : null,
      data.lang
    );
    return result;
  }

  public toFirestore() {
    return {
      active: this.active,
      user: this.user,
      name: this.name,
      target: this.target.toFirestore(),
      type: this.type,
      sections: this.sections.map((x) => x.toFirestore()),
      source: this.source,
      lang: this.lang,
    };
  }
  public clone() {
    return new Training(
      this.ref,
      this.active,
      this.user,
      this.name,
      this.target.clone(),
      this.type,
      this.sections.map((x) => x.clone()),
      this.source,
      this.lang
    );
  }
}
