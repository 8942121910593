
import { PropType, defineComponent } from "vue";
import * as Lang from "@/i18n/lang";
import Audit from "@/model/Auditing/Audit";
import { Result } from "@/model/Auditing/Result";
// import { getResults } from "@/firebase/AuditManager";
// import { generatePDFJs } from "./pdfGeneratorJs";
import { getState } from "../../pinia/NavigationState";
import { getAuditConfigurationState } from "@/pinia/audits/AuditConfigurationState";
import { getAuditResultsState } from "@/pinia/audits/AuditResultsState";
import { getState as getAppState } from "@/pinia/AppState";
import { Role } from "@/model/User";
import { getPDFDownloadLink } from "./pdfGeneratorNew";
import { getAuditsState } from "@/pinia/audits/AuditsState";

export default defineComponent({
  props: {
    audits: Array as PropType<Audit[]>,
  },
  data() {
    return {
      Lang: Lang,
      results: [] as Result[],
      selectedAudit: {} as Audit,
    };
  },
  setup() {
    let state = getAuditConfigurationState();
    let auditsState = getAuditsState();
    return { state, auditsState };
  },

  methods: {
    async openAuditDetails(audit: Audit) {
      let navigationState = getState();
      this.state.set(audit, this.state.selectedDatabaseId);
      navigationState.navigate("auditingConfiguration");
    },

    async openAuditResults(audit: Audit) {
      let navigationState = getState();
      getAuditResultsState().set(audit);
      navigationState.navigate("auditingResults");
    },

    async openPDF(result: Result) {
      const downloadURL = await getPDFDownloadLink(result.ref!);
      const downloadLink = document.createElement("a");
      downloadLink.href = downloadURL;
      downloadLink.target = "_blank";
      downloadLink.click();
    },

    isManager(): boolean {
      let role = getAppState().userData!.role;
      return role === Role.Admin || role === Role.Manager;
    },

    // Now opens new view instead of modal
    // async openResultsModal(audit: Audit) {
    //   let refs = <any>this.$refs;
    //   refs.resultsModal.open();
    //   if (this.state.selectedDatabaseId) {
    //     const results = await getResults(audit);

    //     this.results = results;
    //     this.selectedAudit = audit;
    //   }
    // },
    // Now moved to cloud functions
    // async downloadPDF(result: Result) {
    //   const pdfBlob = await generatePDFJs(this.selectedAudit, result);
    //   const downloadLink = document.createElement("a");
    //   downloadLink.href = URL.createObjectURL(pdfBlob);
    //   downloadLink.download = `${
    //     this.selectedAudit.name
    //   }-${result.getEndDateFormat()}.pdf`;

    //   downloadLink.click();
    // },
  },
});
