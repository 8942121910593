import {
  collection,
  addDoc,
  DocumentReference,
  getDocs,
  doc,
  updateDoc,
  query,
  orderBy,
  Timestamp,
} from "firebase/firestore";

import Audit from "@/model/Auditing/Audit";
import { Result } from "@/model/Auditing/Result";
import * as Firebase from "@/firebase/Firebase";
import { SnapshotListener } from "@/utils/SnapshotListener";
import { defineStore } from "pinia";
import { Role } from "@/model/User";

interface AuditManager {
  audits: Map<String, SnapshotListener<Audit>> | null;
}

export const getAuditManager = defineStore("AuditManager", {
  state: (): AuditManager => {
    return {
      audits: new Map<String, SnapshotListener<Audit>>(),
    };
  },
  actions: {
    async ensureAuditListener(databaseId: string) {
      if (this.audits?.has(databaseId)) {
        return;
      }

      this.audits?.forEach((audit) => {
        audit.dispose();
      });

      this.audits?.clear();

      let q = query(
        collection(Firebase.firestore, "databases/" + databaseId + "/audits"),
        orderBy("name")
      );

      let listener = new SnapshotListener<Audit>(Audit.fromFirestore, q);
      await listener.ensureInit();
      this.audits?.set(databaseId, listener);
    },

    async getAudits(
      role: string | undefined,
      databaseId: string
    ): Promise<Audit[]> {
      if (
        role == Role.Admin ||
        role == Role.Manager ||
        role === Role.Distributor
      ) {
        return this.audits?.get(databaseId)?.items!;
      } else {
        return [];
      }
    },

    getAuditListener(databaseId: string): SnapshotListener<Audit> {
      return this.audits?.get(databaseId)!;
    },

    async getResults(audit: Audit): Promise<Result[]> {
      const resultCollection = collection(
        Firebase.firestore,
        `${audit.ref?.path}/audit_results`
      );
      const resultQuery = await getDocs(resultCollection);

      const results = await Promise.all(
        resultQuery.docs.map(async (doc) => await Result.fromFirestore(doc))
      );

      results.sort((a, b) => {
        const timestampA = a.end_date as Timestamp;
        const timestampB = b.end_date as Timestamp;

        return timestampB.toMillis() - timestampA.toMillis();
      });

      return results;
    },
  },
});
