import { defineStore } from "pinia";
import User from "@/model/User";
import Store from "@/model/Store";
import Task from "@/model/Task";
import StoreTaskConfiguration from "@/model/Tasks/StoreTaskConfiguration";
import TaskConfiguration from "@/model/Tasks/TaskConfiguration";
import DatabaseTaskConfiguration from "@/model/Tasks/DatabaseTaskConfiguration";
import { ModuleEnum } from "@/model/ModuleEnum";
import TaskType from "@/model/Tasks/TaskType";
import { getElementManager } from "@/firebase/ElementManager";
import { collection, doc } from "firebase/firestore";
import * as Firebase from "@/firebase/Firebase";

interface StoreTaskConfigurationState {
  initialTaskConfiguration: StoreTaskConfiguration | null;
  taskConfiguration: StoreTaskConfiguration | null;
  store: Store | null;
  creation: boolean;
  selectedModule: ModuleEnum | null;
  changeSaved: boolean;
  selectedTaskType: TaskType | null;
  taskTypes: TaskType[];
}

export const getStoreTaskConfigurationState = defineStore(
  "storeTaskConfigurationState",
  {
    state: (): StoreTaskConfigurationState => {
      return {
        initialTaskConfiguration: null,
        taskConfiguration: null,
        store: null,
        creation: false,
        selectedModule: null,
        changeSaved: false,
        selectedTaskType: null,
        taskTypes: [],
      };
    },
    actions: {
      getDatabaseId() {
        return this.store?.database!.id!.toString();
      },

      async setup() {
        await getElementManager().ensureDatabaseElementListener(
          this.store?.database?.id!
        );

        await getElementManager().ensureStoreElementListener(
          this.store?.ref?.id!
        );
      },

      set(taskConfiguration: TaskConfiguration | null, store: Store) {
        this.store = store;
        this.creation = taskConfiguration == null;
        this.taskConfiguration = null;
        this.selectedTaskType = null;

        this.selectedModule = ModuleEnum.Action;
        this.changeSaved = false;

        if (this.creation) {
          this.taskConfiguration = new StoreTaskConfiguration(
            doc(
              collection(
                Firebase.firestore,
                store.ref.path,
                "task_configurations"
              )
            ),
            true
          );
          this.taskConfiguration!.recurrence = "FREQ=DAILY;INTERVAL=1";
        } else if (taskConfiguration instanceof StoreTaskConfiguration) {
          this.initialTaskConfiguration = taskConfiguration;
          this.taskConfiguration = StoreTaskConfiguration.clone(
            this.initialTaskConfiguration
          );
        } else {
          this.initialTaskConfiguration = StoreTaskConfiguration.fromOrigin(
            <DatabaseTaskConfiguration>taskConfiguration,
            store
          );
          this.taskConfiguration = StoreTaskConfiguration.clone(
            this.initialTaskConfiguration
          );
        }

        console.log("TaskConfiguration", this.taskConfiguration);
        console.log("InitialTaskConfiguration", this.initialTaskConfiguration);
      },
    },
  }
);
