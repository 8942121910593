
import { defineComponent } from "vue";
import SeSnackbar from "./SeSnackbar.vue";
import * as Filesystem from "@/firebase/Filesystem";
import * as GCoreClient from "@/utils/GCoreClient";
import * as Snackbars from "@/utils/Snackbars";
import { getAttachmentManager } from "@/firebase/AttachmentManager";
import { AttachmentType } from "@/model/Filesystem/AttachmentType";
import UploadStrategy from "@/model/Uploads/UploadStategy";
import SeProgressBar from "../SeProgressBar.vue";

export default defineComponent({
  components: {
    SeSnackbar,
    SeProgressBar,
  },
  props: {
    uploadStrategy: {
      type: UploadStrategy,
      required: true,
      default: null,
    },
  },

  data() {
    return {
      progressValue: 0,
    };
  },

  methods: {},
  watch: {
    "uploadStrategy.progress": {
      handler(newValue) {
        this.progressValue = newValue.value / 100;
      },
      immediate: true,
      deep: true,
    },
  },
});
