
import { defineComponent, nextTick } from "@vue/runtime-core";
import { HalfCircleSpinner } from "epic-spinners";

export default defineComponent({
  components: { HalfCircleSpinner },
  expose: ["toggleLoading", "isLoading"],
  props: {
    icon: String,
    iconSize: {
      type: String,
      default: "22",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledWithoutStyles: {
      type: Boolean,
      default: false,
    },
    invert: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
  data() {
    return { loading: false };
  },
  methods: {
    isLoading() {
      return this.loading;
    },
    toggleLoading(toggle: boolean) {
      this.loading = toggle;

      if (toggle) {
        this.$el.style.backgroundColor = "#2d3291";
      } else {
        this.$el.style.backgroundColor = "#525aff";
      }
    },
  },
});
