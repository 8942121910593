
import * as Lang from "@/i18n/lang";
import { getUserManager } from "../firebase/UserManager";
import { getStoreManager } from "../firebase/StoreManager";
import Store from "../model/Store";
import User, { Role } from "../model/User";
import { getState } from "../pinia/NavigationState";
import { getState as getAppState } from "@/pinia/AppState";
import { defineComponent } from "vue";
import * as DistributorManager from "../firebase/DistributorManager";
import WideCard from "../components/global/WideCard.vue";
import Distributor from "@/model/Distributor";
import * as Storage from "@/firebase/Storage";
import * as Snackbars from "@/utils/Snackbars";

import { getAccountState } from "@/pinia/accounts/AccountState";
import { getAuth } from "firebase/auth";
import Tag from "@/model/Tag";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import SeDropdownFilter from "@/components/global/SeDropdownFilter.vue";
import * as SecureatServerApi from "@/utils/SecureatServerApi";
import * as Modals from "@/utils/Modals";
import SeTextarea from "@/components/global/SeTextarea.vue";
import SeButton from "@/components/global/SeButton.vue";
import SecureatIllustration from "@/model/utils/SecureatIllustration";

export default defineComponent({
  async setup() {
    let state = getAccountState();

    let stores = [] as Store[];

    let user = getAppState().userData!;

    if (user.role == Role.Admin) {
      stores = await getStoreManager().getStoresOfUser(state.selectedAccount!);
    } else {
      //  stores = await StoreManager.getStores(user, false);
    }

    await getDatabaseManager().initialize();

    //test

    var databases = await getDatabaseManager().getDatabases();

    var storeTags = [] as Tag[];

    for (let database of databases) {
      var tags = await getDatabaseManager().getStoreTags(database);

      for (let tag of tags) {
        (<any>tag).databaseName = database.name;
        storeTags.push(tag);
      }
    }

    return {
      Role: Role,
      state: state,
      user: user,
      stores: stores,
      storeTags: storeTags,
    };
  },
  data() {
    return {
      token: "",
      Lang: Lang,
    };
  },
  async mounted() {
    if (
      this.state.selectedAccount.role == Role.Manager &&
      this.user.role == Role.Manager
    ) {
      var refs = <any>this.$refs;

      var selectedTags = [];

      for (let tagRef of this.state.selectedAccount.manager_store_tags) {
        let tag = this.storeTags.find((x) => x.ref.id == tagRef.id);
        if (tag) {
          selectedTags.push(tag);
        }
      }

      console.log(selectedTags);

      refs.managerStoreTags.setSelectedItems(selectedTags);
    }
  },
  methods: {
    copyToken() {
      navigator.clipboard
        .writeText(this.token)
        .then(() => {
          Snackbars.display("Texte copié dans le presse-papiers !");
        })
        .catch((err) => {
          Snackbars.display("Erreur lors de la copie : ", err);
        });
    },
    async disconnectForPasswordChange() {
      this.user.new_password_required = true;

      await this.user.set();

      let auth = getAuth();
      this.disconnect = true;
      this.state.logoutRequest = true;
      getAppState().token = null;
      await auth.signOut();
      this.state.logoutRequest = false;
      location.reload();
    },
    openPasswordModal() {
      var refs = <any>this.$refs;
      refs.pwdModal.open();
    },
    async onIllustrationChange(event: ProgressEvent, file: File) {
      await Storage.uploadAccountIllustration(
        this.state.selectedAccount!,
        file
      );

      this.state.selectedAccount!.illustration_url = new SecureatIllustration(
        await Storage.getAccountIllustrationUrl(this.state.selectedAccount!)
      );

      console.log(this.state.selectedAccount.illustration_url);

      await this.state.selectedAccount!.set();
    },
    async createLoginToken() {
      var refs = <any>this.$refs;

      refs.tokenBtn.toggleLoading(true);

      this.token = await SecureatServerApi.createLoginToken(
        this.state.selectedAccount!.ref.id
      );

      console.log(this.token);
      refs.tokenModal.open();

      refs.tokenBtn.toggleLoading(false);
    },
    async updateAccount() {
      let refs = <any>this.$refs;
      refs.modifyBtn.toggleLoading(true);

      if (refs.managerStoreTags) {
        let selectedTags = refs.managerStoreTags.getSelectedItems() as Tag[];

        this.state.selectedAccount!.manager_store_tags = selectedTags.map(
          (x) => x.ref
        );
      }

      console.log(this.state.selectedAccount.illustration_url);
      await this.state.selectedAccount?.set();

      refs.modifyBtn.toggleLoading(false);

      Snackbars.display(Lang.getI18N("saved-successfully"));
    },
  },
  components: { WideCard },
});
