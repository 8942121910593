import { defineStore } from "pinia";
import { Role } from "@/model/User";
import { getStoreManager } from "@/firebase/StoreManager";
import Distributor from "@/model/Distributor";
import Database from "@/model/Database";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { getState } from "../AppState";
import { getDistributorManager } from "@/firebase/DistributorManager";

interface StoresState {
  databases: Database[];
  search: string;
  selectedDistributor: Distributor | null;
  selectedDatabase: Database | null;
  displayUnactives: boolean;
  distributors: Distributor[];
  selectedUtc: string;
}

export const getStoresState = defineStore("storestate", {
  state: (): StoresState => {
    return {
      databases: [],
      search: "",
      selectedDistributor: null,
      selectedDatabase: null,
      displayUnactives: false,
      distributors: [],
      selectedUtc: "Europe/Paris",
    };
  },
  actions: {
    async setup() {
      var state = getState();

      await getDistributorManager().initialize();

      await getStoreManager().initialize();
      this.distributors = getDistributorManager().getDistributors();

      if (state.userData!.role == Role.Admin) {
        this.databases = await getDatabaseManager().getDistributorDatabases(
          this.distributors[0].ref!
        );
      } else if (state.userData!.role == Role.Distributor) {
        this.databases = await getDatabaseManager().getDistributorDatabases(
          state.userData?.distributor!
        );
      }

      this.databases = this.databases.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    },
  },
});
