import { defineStore } from "pinia";
import Database from "@/model/Database";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import DatabaseTaskConfiguration from "@/model/Tasks/DatabaseTaskConfiguration";
import { getTaskConfigurationManager } from "@/firebase/TaskConfigurationManager";
import { getModuleFromEnum, getModuleFromRef } from "@/firebase/ModuleManager";
import { ModuleEnum } from "@/model/ModuleEnum";
import Module from "@/model/Module";
import * as ModuleManager from "@/firebase/ModuleManager";
import TaskType from "@/model/Tasks/TaskType";
import { getDatabaseTaskTypes } from "@/firebase/TaskTypeManager";
import { DocumentReference } from "firebase/firestore";
import * as ObjectUtils from "@/utils/ObjectUtils";

interface DatabaseTaskState {
  database: Database | null;

  targetDatabase: Database | null;
  databases: Database[];

  tasks: DatabaseTaskConfiguration[];
  baseTasks: DatabaseTaskConfiguration[];
  filter: number;

  selectedModule: ModuleEnum | null;
  taskTypes: TaskType[];

  search: String;

  preventReload: boolean;

  displayUnactives: boolean;
}

export const BulkFilters = {
  General: 0,
  Actions: 1,
  Temperatures: 2,
  Oils: 3,
};

export const getBulkDatabaseTaskState = defineStore("BulkDatabaseTaskState", {
  state: (): DatabaseTaskState => {
    return {
      targetDatabase: null,
      database: null,
      databases: [],
      tasks: [] as DatabaseTaskConfiguration[],
      baseTasks: [],
      filter: BulkFilters.General,
      selectedModule: ModuleEnum.Action,
      taskTypes: [],
      search: "",
      preventReload: false,
      displayUnactives: false,
    };
  },
  actions: {
    hasChanges() {
      for (let i = 0; i < this.tasks.length; i++) {
        if (i >= this.baseTasks.length) {
          break;
        }
        this.baseTasks[i].ref = this.tasks[i].ref;
        if (!ObjectUtils.compareObjects(this.tasks[i], this.baseTasks[i])) {
          return true;
        }
      }

      return false;
    },

    async setup() {
      if (this.preventReload) {
        this.preventReload = false;
        return;
      }

      this.tasks = getTaskConfigurationManager()
        .getDatabaseTaskConfigurations(this.database.ref!.id)
        .map((x) => DatabaseTaskConfiguration.clone(x));

      this.baseTasks = this.tasks.map((x: DatabaseTaskConfiguration) =>
        DatabaseTaskConfiguration.clone(x)
      );

      this.taskTypes = await getDatabaseTaskTypes(this.database.ref.id!);

      await getDatabaseManager().initialize();

      this.modules = ModuleManager.getModules();

      this.databases = getDatabaseManager().getDatabases();
    },

    getTypeName(ref: DocumentReference) {
      let type = this.taskTypes.find((x) => x.ref.id == ref.id);

      if (type) {
        return type.name;
      }
      return "?";
    },
    getTaskType(ref: DocumentReference) {
      return this.taskTypes.find((x) => x.ref.id == ref.id);
    },
    getTypeColor(ref: DocumentReference) {
      let type = this.taskTypes.find((x) => x.ref.id == ref.id);

      if (type) {
        return type.color;
      }
      return "black";
    },
    set(database: Database) {
      this.database = database;
    },
    setFilter(filter: number) {
      this.filter = filter;
    },
  },
  getters: {
    displayTasks(): DatabaseTaskConfiguration[] {
      let results: DatabaseTaskConfiguration[] = [];

      switch (this.filter) {
        case BulkFilters.General:
          results = this.tasks.filter(
            (x) =>
              this.search == "" ||
              x.name.toLowerCase().includes(this.search.toLowerCase())
          );
          break;
        case BulkFilters.Actions:
          results = this.tasks.filter(
            (x: DatabaseTaskConfiguration) =>
              getModuleFromRef(x.module) ===
                getModuleFromEnum(ModuleEnum.Action) &&
              (this.search == "" ||
                x.name.toLowerCase().includes(this.search.toLowerCase()))
          );
          break;
        case BulkFilters.Temperatures:
          results = this.tasks.filter(
            (x: DatabaseTaskConfiguration) =>
              getModuleFromRef(x.module) ===
                getModuleFromEnum(ModuleEnum.Temperatures) &&
              (this.search == "" ||
                x.name.toLowerCase().includes(this.search.toLowerCase()))
          );
          break;
        case BulkFilters.Oils:
          results = this.tasks.filter(
            (x: DatabaseTaskConfiguration) =>
              getModuleFromRef(x.module) ===
                getModuleFromEnum(ModuleEnum.Oils) &&
              (this.search == "" ||
                x.name.toLowerCase().includes(this.search.toLowerCase()))
          );
          break;
      }

      return results.filter((x) => x.active || this.displayUnactives);
    },
    isGeneral() {
      return this.filter === BulkFilters.General;
    },
    isActions() {
      return this.filter === BulkFilters.Actions;
    },
    isTemperatures() {
      return this.filter === BulkFilters.Temperatures;
    },
    isOils() {
      return this.filter === BulkFilters.Oils;
    },
  },
});
