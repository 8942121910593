import {
  collection,
  query,
  where,
  doc,
  getDocs,
  getDoc,
  addDoc,
  documentId,
  updateDoc,
  DocumentReference,
  orderBy,
  WriteBatch,
  setDoc,
  Query,
  DocumentData,
  Firestore,
} from "firebase/firestore";

import Element from "@/model/Elements/Element";
import DatabaseLabel from "@/model/Labels/DatabaseLabel";

import * as Storage from "./Storage";
import * as DatabaseManager from "./DatabaseManager";

import { getState } from "../pinia/AppState";
import ElementType from "@/model/ElementType";
import DatabaseElement from "@/model/Elements/DatabaseElement";
import StoreElement from "@/model/Elements/StoreElement";
import Store from "@/model/Store";
import * as LabelsManager from "@/firebase/LabelsManager";
import StoreLabel from "@/model/Labels/StoreLabel";
import { ModuleEnum } from "@/model/ModuleEnum";
import { getFunctions, httpsCallable } from "firebase/functions";

import Database from "../model/Database";
import User, { Role } from "../model/User";
import Distributor from "@/model/Distributor";
import * as DistributorManager from "@/firebase/DistributorManager";
import * as UserManager from "@/firebase/UserManager";
import * as Firebase from "@/firebase/Firebase";
import { defineStore } from "pinia";
import { SnapshotListener } from "@/utils/SnapshotListener";
import * as ElementTypeManager from "@/firebase/ElementTypeManager";
import { Ref, ref } from "vue";
import { downloadBytes } from "@/utils/DownloadUtils";
import * as Filesystem from "@/firebase/Filesystem";
import * as SecureatServerApi from "@/utils/SecureatServerApi";

interface ElementManager {
  databaseElements: Map<String, SnapshotListener<DatabaseElement>> | null;
  storeElements: Map<String, SnapshotListener<StoreElement>> | null;
}

export const getElementManager = defineStore("ElementManager", {
  state: (): ElementManager => {
    return {
      databaseElements: new Map<String, SnapshotListener<DatabaseElement>>(),
      storeElements: new Map<String, SnapshotListener<StoreElement>>(),
    };
  },
  actions: {
    //#region Database

    async ensureDatabaseElementListener(databaseId: string) {
      if (this.databaseElements?.has(databaseId)) {
        return;
      }
      let q = query(
        collection(Firebase.firestore, "databases/" + databaseId + "/elements"),
        orderBy("name")
      );

      let listener = new SnapshotListener<DatabaseElement>(
        DatabaseElement.fromFirestore,
        q
      );

      await listener.ensureInit();

      this.databaseElements?.set(databaseId, listener);
    },
    getDatabaseElements(databaseId: string): DatabaseElement[] {
      return this.databaseElements?.get(databaseId)?.items!;
    },
    getDatabaseElementListener(
      databaseId: string
    ): SnapshotListener<DatabaseElement> {
      return this.databaseElements?.get(databaseId)!;
    },
    getDatabaseElementByReference(
      databaseId: string,
      reference: DocumentReference
    ) {
      let elements = this.getDatabaseElements(databaseId);

      let result = elements.find((x) => x.ref?.id === reference.id);
      return result ? result : null;
    },

    async updateDatabaseElement(databaseId: string, element: DatabaseElement) {
      // is constant for now
      element.illustration_path =
        "databases/" + databaseId + "/element_pictures/" + element.ref!.id;

      var data = element.toFirestore();

      await updateDoc(element.ref!, data);

      return element;
    },
    async createDatabaseElement(
      databaseId: string,
      element: DatabaseElement,
      type: ElementType | null
    ) {
      let ref = await addDoc(
        collection(
          Firebase.firestore,
          "/databases/" + databaseId + "/elements/"
        ),
        element.toFirestore()
      );

      let elementId = ref.id;

      element.ref = ref;
      await this.updateDatabaseElement(databaseId, element);
    },
    //#endregion

    //#region Store
    getStoreElementListener(storeId: string) {
      return this.storeElements?.get(storeId)!;
    },
    async ensureStoreElementListener(storeId: string) {
      if (this.storeElements?.has(storeId)) {
        return;
      }

      let q = query(
        collection(Firebase.firestore, "stores/" + storeId + "/elements/"),
        orderBy("name")
      );

      let listener = new SnapshotListener<StoreElement>(
        StoreElement.fromFirestore,
        q
      );

      await listener.ensureInit();

      this.storeElements?.set(storeId, listener);
    },

    getElements(store: Store) {
      let results: Element[] = [];

      let storeElements = this.storeElements?.get(store.ref?.id!)?.items!;

      let databaseElements = this.getDatabaseElements(store.database?.id!);

      // Store Tasks without origins
      for (let storeElement of storeElements) {
        let originValue = databaseElements.find(
          (x) =>
            storeElement.origin != null && x.ref!.id == storeElement.origin!.id
        );

        if (originValue != undefined) {
          storeElement.setOriginValue(originValue!);
        }
      }

      for (let databaseElement of databaseElements) {
        let exist = storeElements.some(
          (x) =>
            x.originValue != null &&
            x.originValue!.ref!.id == databaseElement.ref!.id
        );

        if (!exist) {
          results.push(databaseElement);
        }
      }

      results = results.concat(storeElements);

      return results.sort((a, b) => a.getName()!.localeCompare(b.getName()!));
    },

    getStoreElementByReference(
      storeId: string,
      reference: DocumentReference
    ): StoreElement | null {
      let elements = this.storeElements!.get(storeId);

      let result = elements?.items.find((x) => x.ref?.id === reference.id!);

      return result ? result : null;
    },

    async getStoreElementsByModule(module: ModuleEnum, store: Store) {
      let elements = await this.getElements(store);

      switch (module) {
        case ModuleEnum.Temperatures:
          return elements.filter((x) => x.getTemperaturesModuleActive());
        case ModuleEnum.Traceability:
          return elements.filter((x) => x.getTraceabilityModuleActive());
        case ModuleEnum.Oils:
          return elements.filter((x) => x.getOilsModuleActive());
        case ModuleEnum.Action:
          return [];
      }

      throw new Error("Module " + module + " is not handled");
    },

    async getElementsByModule(module: string, databaseId: string) {
      let fieldName = module + "_module_active";

      const moduleQuery = query(
        collection(
          Firebase.firestore,
          "/databases/" + databaseId + "/elements"
        ),
        where(fieldName, "==", true)
      );

      let snapshot = await getDocs(moduleQuery);

      return snapshot.docs.map((x) => DatabaseElement.fromFirestore(x));
    },

    async getIllustrationProposals(keyword: string, count: number) {
      let functions = getFunctions(Firebase.firebase);

      const getUnsplashImages = httpsCallable(functions, "getUnsplashImages");

      try {
        let result = await getUnsplashImages({
          keyword: keyword,
          count: count,
        });

        return result;
      } catch (e: any) {
        console.log(e);
      }
    },

    async createStoreElement(
      store: Store,
      element: StoreElement,
      batch: WriteBatch | null = null
    ) {
      let path = "/stores/" + store.ref!.id + "/elements/";

      let ref: DocumentReference = doc(collection(Firebase.firestore, path));

      element.ref = ref;

      if (batch === null) {
        ref = await addDoc(
          collection(Firebase.firestore, path),
          element.toFirestore()
        );
      } else {
        ref = doc(collection(Firebase.firestore, path));
        await batch.set(ref, element.toFirestore());
      }

      element.ref = ref;

      return element;
    },

    getElementByReference(
      reference: DocumentReference,
      contextualStore: Store
    ): Element {
      let databaseElement = this.getDatabaseElementByReference(
        contextualStore.database?.id!,
        reference
      );

      if (databaseElement != null) {
        let storeElement = this.storeElements!.get(
          contextualStore.ref.id
        )?.items.find((x) => x.origin?.id == databaseElement!.ref.id);

        if (storeElement != null) {
          storeElement.setOriginValue(databaseElement);
          return storeElement;
        }
        return databaseElement;
      }

      let storeElement = this.getStoreElementByReference(
        contextualStore.ref!.id!,
        reference
      );

      if (storeElement != null && storeElement.origin != null) {
        let originValue = this.getDatabaseElementByReference(
          contextualStore.database?.id!,
          storeElement.origin
        );
        storeElement.setOriginValue(originValue!);
      }
      return storeElement!;
    },

    //#endregion
  },
});
