import { vShow as _vShow, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-51d94fc3"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "img-ctn",
      style: _normalizeStyle(_ctx.cssPropsContainer)
    }, [
      _withDirectives(_createElementVNode("img", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
        class: _normalizeClass(_ctx.class),
        style: _normalizeStyle(_ctx.cssProps),
        src: _ctx.cdn ? _ctx.ImageUtils.toImageKit(_ctx.src!)! : _ctx.src,
        onLoad: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ready = true))
      }, null, 46, _hoisted_1), [
        [_vShow, _ctx.ready]
      ])
    ], 4),
    _createElementVNode("div", null, [
      (!_ctx.ready)
        ? _renderSlot(_ctx.$slots, "default", { key: 0 }, undefined, true)
        : _createCommentVNode("", true)
    ])
  ]))
}