
import { PropType, defineComponent, nextTick } from "vue";
import Communication from "@/model/Communication/Communication";
import { getCommunicationState } from "@/pinia/communications/CommunicationState";
import Database from "@/model/Database";
import * as Lang from "@/i18n/lang";
import SeTab from "../global/SeTab.vue";
import Tag from "@/model/Tag";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { getStoreManager } from "@/firebase/StoreManager";
import Store from "@/model/Store";
import { DocumentReference } from "firebase/firestore";
import EmployeeRole from "@/model/EmployeeRole";
import SeDropdownItem from "../global/dropdown/SeDropdownItem.vue";
import { CommunicationTargetType } from "@/components/communication/CommunicationTargetType";

export default defineComponent({
  expose: ["applyFilters", "displayFilters"],

  data() {
    return {
      CommunicationTargetType: CommunicationTargetType,
      Lang: Lang,
      state: getCommunicationState(),
      roles: [] as EmployeeRole[],
      targetType: CommunicationTargetType.roles,
    };
  },

  async mounted() {
    this.roles = await getDatabaseManager().getEmployeeRoles(this.database.ref);
  },
  props: {
    database: {
      type: Database,
      required: true,
    },
  },
  methods: {
    displayFilters() {
      var refs = <any>this.$refs;

      if (this.state.communication!.target.employee_roles.length > 0) {
        this.targetType = CommunicationTargetType.roles;

        var roles = this.state.communication!.target.employee_roles.map((x) =>
          this.roles.find((y) => y.ref.id == x.id)
        );

        console.log(refs.roles);

        nextTick(() => {
          refs.roles.setSelectedItems(roles);
        });
      } else {
        this.targetType = CommunicationTargetType.everyone;
      }
    },
    applyFilters() {
      var refs = <any>this.$refs;

      this.state.communication!.target.employee_roles = [];

      if (this.targetType == CommunicationTargetType.roles) {
        var roles: EmployeeRole[] = refs.roles.getSelectedItems();
        this.state.communication!.target.employee_roles = roles.map(
          (x) => x.ref!
        );
      }
    },
  },
});
