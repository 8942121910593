import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5d926bd5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value"]
const _hoisted_2 = {
  key: 1,
  class: "flex align-center justify-start grow",
  style: {"height":"40px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (this.edit || _ctx.editable)
      ? (_openBlock(), _createElementBlock("input", {
          key: 0,
          class: "editable-input",
          ref: "textInput",
          value: _ctx.value == undefined ? _ctx.modelValue : _ctx.value,
          type: "text",
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput($event))),
          style: _normalizeStyle({ width: _ctx.inputWidth + 'px' })
        }, null, 44, _hoisted_1))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
            class: "text-muted",
            ref: "textSpan"
          }, _toDisplayString(_ctx.value == undefined ? _ctx.modelValue : _ctx.value), 513)
        ]))
  ]))
}