import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { AttachmentType } from "./AttachmentType";
import * as DOMUtils from "@/utils/DOMUtils";
import UploadStrategy from "../Uploads/UploadStategy";
import { StrategyEnum } from "../Uploads/StrategyEnum";
import * as SecureatServerApi from "@/utils/SecureatServerApi";

export default class Attachment {
  async download() {
    if (this.video_id != null) {
      var link = await SecureatServerApi.getVideoDownloadLink(this.video_id);
      DOMUtils.forceDownloadFromUrl(link, this.name);
      return;
    }

    if (this.url != null) {
      DOMUtils.downloadFileFromUrl(this.url!, this.name);
    } else {
      var storage = getStorage();
      var url = await getDownloadURL(ref(storage, this.path!));
      DOMUtils.downloadFileFromUrl(url!, this.name);
    }
  }

  clone(): Attachment | null {
    return new Attachment(
      this.name,
      this.type,
      this.path,
      this.url,
      this.video_id
    );
  }
  name: string = "";
  type: AttachmentType = AttachmentType.Image;
  path: string | null = null;
  url: string | null = null;
  video_id: string | null = null;

  constructor(
    name: string,
    type: AttachmentType,
    path: string | null,
    url: string | null,
    video_id: string | null
  ) {
    this.name = name;
    this.type = type;
    this.path = path;
    this.url = url;
    this.video_id = video_id;
  }

  async generateUrl() {
    if (this.url == null) {
      const storage = getStorage();

      if (this.path == null) {
        throw new Error("Invalid attachement, no path specified.");
      }
      this.url = await getDownloadURL(ref(storage, this.path!));
    }
  }

  toFirestore(): any {
    return {
      name: this.name,
      type: this.type,
      path: this.path,
      url: this.url,
      video_id: this.video_id,
    };
  }
  public static fromFirestore(x: any) {
    return new Attachment(
      x.name,
      x.type,
      x.path,
      x.url == undefined ? null : x.url,
      x.video_id == undefined ? null : x.video_id
    );
  }
}
