import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-layout" }
const _hoisted_2 = { class: "container flex col" }
const _hoisted_3 = { class: "center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavHeader = _resolveComponent("NavHeader")!
  const _component_SearchBar = _resolveComponent("SearchBar")!
  const _component_SeSimpleTab = _resolveComponent("SeSimpleTab")!
  const _component_ProbeList = _resolveComponent("ProbeList")!
  const _component_HalfCircleSpinner = _resolveComponent("HalfCircleSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavHeader, { title: "Probes" }),
    _createVNode(_component_SearchBar, {
      targetGridId: "probeGrid",
      modelValue: _ctx.state.displayUnactives,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.displayUnactives) = $event)),
      ref: "searchBar",
      class: "sticky"
    }, null, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SeSimpleTab, {
        ref: "filters",
        onSwitch: _ctx.onFilterSwitch,
        labels: [
          _ctx.Lang.getI18N('probes.all'),
          _ctx.Lang.getI18N('probes.working'),
          _ctx.Lang.getI18N('probes.not-working'),
        ]
      }, null, 8, ["onSwitch", "labels"]),
      (_openBlock(), _createBlock(_Suspense, null, {
        fallback: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_HalfCircleSpinner, {
              "animation-duration": 1000,
              size: 60,
              color: "#454eff"
            })
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_ProbeList, {
            unactives: _ctx.state.displayUnactives,
            ref: "list",
            probes: _ctx.state.visibleProbes
          }, null, 8, ["unactives", "probes"])
        ]),
        _: 1
      }))
    ])
  ]))
}