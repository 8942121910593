import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-66ec82ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "frame" }
const _hoisted_2 = {
  key: 0,
  style: {"width":"40px","height":"40px"}
}
const _hoisted_3 = { class: "flex col align-start justify-start g0" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "subtitle" }
const _hoisted_6 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SePlaceholder = _resolveComponent("SePlaceholder")!
  const _component_SeImage = _resolveComponent("SeImage")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClick())),
    class: _normalizeClass({
      card: true,
      unactive: !_ctx.active,
      highlight: _ctx.isHighlighted,
      selected: _ctx.isSelected,
    })
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.icon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_SeImage, {
              maxHeight: "40",
              maxWidth: "40",
              src: _ctx.icon
            }, {
              default: _withCtx(() => [
                (_ctx.icon)
                  ? (_openBlock(), _createBlock(_component_SePlaceholder, {
                      key: 0,
                      style: {"width":"40px","height":"40px"}
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["src"])
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "pretitle", {}, undefined, true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.title), 1),
        _createElementVNode("span", _hoisted_5, [
          _renderSlot(_ctx.$slots, "subtitle", {}, undefined, true)
        ])
      ]),
      _renderSlot(_ctx.$slots, "body", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
    ])
  ], 2))
}