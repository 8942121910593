import { DocumentSnapshot, DocumentReference, doc } from "firebase/firestore";
import SecureatSnapshot from "../SecureatSnapshot";
import { Section } from "./Section";
import { Question, QuestionType } from "./Question";
import { QuestionCondition } from "./QuestionCondition";
import { Result } from "./Result";
import { Answer } from "./Answer";
import { MultipleQuestionOptions } from "./QuestionOptions";
import { x } from "pdfkit";

export default class Audit extends SecureatSnapshot {
  active: boolean;
  name: string;
  published: boolean;
  creation_date: Date = new Date();
  sections: Section[];
  publishing_date: Date | null;
  self_evaluation: boolean;

  constructor(
    ref: DocumentReference,
    active: boolean,
    name: string,
    published: boolean,
    self_evaluation: boolean,
    creation_date: Date,
    sections: Section[] = [],
    publishing_date: Date | null = null
  ) {
    super(ref);
    this.active = active;
    this.name = name;
    this.published = published;
    this.self_evaluation = self_evaluation;
    this.creation_date = creation_date;
    this.sections = sections;
    this.publishing_date = publishing_date;
  }

  public static fromFirestore(snapshot: DocumentSnapshot): Audit {
    const data = snapshot.data()!;

    let sections: Section[] = data.sections.map((sectionData: any) => {
      let questions: Question[] = sectionData.questions.map(
        (questionData: any) => {
          const options = Question.createQuestionOptions(
            questionData.type,
            questionData.options
          );
          let newConditions: QuestionCondition[] = [];
          if (questionData.conditions && questionData.conditions.length > 0) {
            newConditions = questionData.conditions.map(
              (condition: QuestionCondition) =>
                new QuestionCondition(condition.question_id, condition.value)
            );
          }
          return new Question(
            questionData.required,
            questionData.text,
            questionData.type,
            questionData.score,
            options,
            newConditions,
            questionData.id,
            questionData.archived_at
          );
        }
      );
      return new Section(
        sectionData.title,
        false,
        questions,
        sectionData.id,
        sectionData.archived_at
      );
    });

    let result = new Audit(
      snapshot.ref,
      data.active != undefined ? data.active : true,
      data.name,
      data.published,
      data.self_evaluation ? data.self_evaluation : false,
      data.creation_date,
      sections,
      data.publishing_date
    );

    return result;
  }

  public toFirestore() {
    return {
      active: this.active,
      name: this.name,
      published: this.published,
      self_evaluation: this.self_evaluation,
      creation_date: this.creation_date,
      sections: this.sections.map((section) => section.toMap()),
      publishing_date: this.publishing_date,
    };
  }

  public addSection(section: Section): void {
    this.sections.push(section);
  }

  static clone(audit: Audit, exact: boolean = false) {
    const clonedSections: Section[] = audit.sections.map((section) => {
      return section.clone(exact);
    });

    if (exact) {
      return new Audit(
        audit.ref,
        audit.active,
        audit.name,
        audit.published,
        audit.self_evaluation,
        audit.creation_date,
        clonedSections,
        audit.publishing_date
      );
    } else {
      return new Audit(
        doc(audit.ref.parent),
        true,
        `${audit.name} (Copy)`,
        false,
        false,
        new Date(),
        clonedSections,
        null
      );
    }
  }

  calculateTotalScore(): number {
    let totalScore = 0;
    this.sections.forEach((section) => {
      totalScore += section.calculateTotalScore();
    });
    return totalScore;
  }

  getSections(): Section[] {
    return this.sections.filter((section) => section.archived_at === null);
  }

  calculateQuestionsAmount(): number {
    return this.getSections().reduce((totalQuestions, section) => {
      return totalQuestions + section.getQuestions().length;
    }, 0);
  }

  getSectionsAmount(): number {
    return this.getSections().length;
  }

  getQuestionById(questionId: string) {
    for (const section of this.getSections()) {
      const foundQuestion = section
        .getQuestions()
        .find((question) => question.id === questionId);
      if (foundQuestion) {
        return foundQuestion;
      }
    }
    return null;
  }

  getConditionalQuestions(question: Question): Question[] {
    let conditionalQuestions = [] as Question[];
    if (!question.conditions || question.conditions.length <= 0)
      return conditionalQuestions;
    question.conditions.forEach((condition) => {
      this.getSections().map((section) => {
        section.getQuestions().map((question) => {
          if (question.id === condition.question_id) {
            conditionalQuestions.push(question);
            return;
          }
        });
      });
    });

    return conditionalQuestions;
  }

  mergeResultsWithAudit(results: Result[]) {
    const sectionQuestionAnswerMap = new Map<
      Section,
      Map<Question, Answer | null>
    >();

    this.getSections().forEach((section) => {
      const questionAnswerMap = new Map<Question, Answer | null>();

      section.getQuestions().forEach((question) => {
        // Find the corresponding answer in the result based on question_id
        question.answers = [];
        results.forEach((result) => {
          let answer = result.answers.find(
            (resultAnswer: { question_id: string; type: QuestionType }) =>
              resultAnswer.question_id === question.id &&
              resultAnswer.type === question.type
          );
          if (answer) {
            if (question.score) {
              if (answer.toPDF() !== "") {
                answer.score = question.score;
              } else {
                answer.score = 0;
              }
            }
            if (answer.type === "multiple_choice") {
              if (answer.choices) {
                console.log("answer.choices", answer.choices.length);
                // Map the selected choice ids to their corresponding text values and scores
                const selectedChoices = answer.choices.map((choiceId) => {
                  const matchingChoice = (
                    question.options as MultipleQuestionOptions
                  )
                    .getChoices()
                    .find((choice) => choice.id === choiceId);
                  return matchingChoice ? matchingChoice : null;
                });

                // Calculate the total score and assign it to answer.score
                answer.score = selectedChoices.reduce((sum, choice) => {
                  return sum + (choice ? choice.score : 0);
                }, 0);

                if (
                  selectedChoices.some(
                    (x) => x != undefined && x.text != undefined
                  )
                ) {
                  answer.choices = selectedChoices.map((x) => x?.text);
                }
              }
            }

            if (answer.type === "yes_or_no") {
              if (answer.toPDF() === true) {
                const questionYes = (
                  question.options as MultipleQuestionOptions
                )
                  .getChoices()
                  .find((choice) => choice.text === "true");

                if (questionYes) {
                  answer.score = questionYes!.score;
                } else {
                  answer.score = 0;
                }
              } else if (answer.toPDF() === false) {
                const questionNo = (question.options as MultipleQuestionOptions)
                  .getChoices()
                  .find((choice) => choice.text === "false");

                answer.score = questionNo!.score;
              } else {
                answer.score = 0;
              }
            }

            answer.store = result.store;
            answer.end_date = result.end_date;
            questionAnswerMap.set(question, answer);
            question.answers?.push(answer);
          } else {
            questionAnswerMap.set(question, null);
            const emptyAnswerData = {
              timestamp: new Date(),
              type: question.type,
              question_id: null,
              rating: null,
              choices: null,
              answer_yes: null,
              text: null,
              file_urls: null,
              number: null,
              date: null,
              score: 0,
              comment: null,
            };
            let emptyAnswer = new Answer(emptyAnswerData);
            emptyAnswer.store = result.store;
            emptyAnswer.end_date = result.end_date;
            question.answers?.push(emptyAnswer);
          }
        });
      });
      sectionQuestionAnswerMap.set(section, questionAnswerMap);
    });

    return sectionQuestionAnswerMap;
  }
}
