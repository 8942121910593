import { defineStore } from "pinia";
import Database from "@/model/Database";
import Distributor from "@/model/Distributor";
import DatabaseElement from "@/model/Elements/DatabaseElement";
import ElementType from "@/model/ElementType";
import * as ElementTypeManager from "@/firebase/ElementTypeManager";
import { getDatabaseManager } from "@/firebase/DatabaseManager";
import { DocumentReference, collection, doc } from "firebase/firestore";
import * as Firebase from "@/firebase/Firebase";
import { getElementManager } from "@/firebase/ElementManager";
import Store from "@/model/Store";
import StoreElement from "@/model/Elements/StoreElement";
import { getStoreManager } from "@/firebase/StoreManager";
import * as ObjectUtils from "@/utils/ObjectUtils";

interface DatabaseElementState {
  store: Store | null;

  targetStore: Store | null;
  stores: Store[];

  elements: StoreElement[];
  displayedElements: StoreElement[];
  baseElements: StoreElement[];
  displayColumns: string[];
  types: ElementType[];
  search: string;
  database: Database | null;
  preventReload: boolean;
}

export const getBulkStoreElementState = defineStore("BulkStoreElementState", {
  state: (): DatabaseElementState => {
    return {
      targetStore: null,
      store: null,
      displayedElements: [],
      baseElements: [],
      stores: [],
      elements: [] as StoreElement[],
      displayColumns: [],
      database: null,
      types: [],
      search: "",
      preventReload: false,
    };
  },
  actions: {
    hasChanges() {
      for (let i = 0; i < this.elements.length; i++) {
        if (i >= this.baseElements.length) {
          break;
        }
        this.baseElements[i].ref = this.elements[i].ref;
        if (
          !ObjectUtils.compareObjects(this.elements[i], this.baseElements[i])
        ) {
          return true;
        }
      }

      return false;
    },

    getTypeName(ref: DocumentReference) {
      let type = this.types.find((x) => x.ref.id == ref.id);

      if (type != undefined) {
        return type.name;
      }
      return "?";
    },
    async setup() {
      if (this.preventReload) {
        this.preventReload = false;
        return;
      }

      this.elements = [];
      this.baseElements = [];

      await getDatabaseManager().initialize();

      this.database = getDatabaseManager().getDatabaseByReferenceFromCache(
        this.store.database
      );

      await getElementManager().ensureStoreElementListener(this.store.ref.id!);

      let allElements = await getElementManager().getElements(this.store);

      for (let element of allElements) {
        if (element instanceof StoreElement) {
          this.elements.push(element);
        } else {
          let databaseElement = element as DatabaseElement;

          var ele = StoreElement.fromOrigin(databaseElement, this.store);

          ele.isRemote = false;
          this.elements.push(ele);
        }
      }

      this.elements = this.elements.map((x) => StoreElement.clone(x));

      this.baseElements = this.elements.map((x) => StoreElement.clone(x));

      await getStoreManager().initialize();

      this.stores = getStoreManager().getStores(false);

      this.types = await ElementTypeManager.getAllElementsTypes(
        this.store,
        this.store.database.id!
      );

      this.displayedElements = this.elements;
    },

    set(store: Store) {
      this.store = store;
    },
  },
});
