import * as ImageUtils from "@/utils/ImageUtils";

export default class SecureatIllustration {
  firestoreUrl: string = "";

  constructor(firestoreUrl: string) {
    this.firestoreUrl = ImageUtils.fromImageKit(firestoreUrl);
  }

  public get() {
    return ImageUtils.toImageKit(this.firestoreUrl);
  }
  public toFirestore() {
    return this.firestoreUrl;
  }
  public static fromFirestore(url: string) {
    return new SecureatIllustration(url);
  }
  public clone(): SecureatIllustration {
    return new SecureatIllustration(this.firestoreUrl);
  }
}
