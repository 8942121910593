import dateFormat from "dateformat";
import { DocumentReference, Timestamp } from "firebase/firestore";
import { QuestionType } from "./Question";
import Store from "../Store";

export class Answer {
  timestamp: Timestamp;
  type: QuestionType;
  question_id: string;
  rating?: number | null;
  choices?: string[] | null;
  answer_yes?: boolean | null;
  text?: string | null;
  file_urls?: string[] | null;
  number?: number | null;
  date?: Timestamp | null;
  score: number | null;
  comment: string | null;
  store: DocumentReference | null | undefined;
  end_date: Timestamp | undefined;

  constructor(data: any) {
    this.timestamp = data.timestamp;
    this.type = data.type;
    this.question_id = data.question_id;
    this.rating = data.rating !== undefined ? data.rating : null;
    this.choices = data.choices !== undefined ? data.choices : [];
    this.answer_yes = data.answer_yes !== undefined ? data.answer_yes : null;
    this.text = data.text !== undefined ? data.text : null;
    this.file_urls = data.file_urls !== undefined ? data.file_urls : null;
    this.date = data.date !== undefined ? data.date : null;
    this.number = data.number !== undefined ? data.number : null;
    this.score = data.score !== undefined ? data.score : null;
    this.comment = data.comment !== undefined ? data.comment : null;
  }

  toPDF() {
    switch (this.type) {
      case "text_input":
        return this.text !== undefined && this.text !== null ? this.text : "";
      case "multiple_choice":
        return this.choices != undefined && this.choices != null
          ? this.choices.join(", ")
          : "";
      case "file_upload":
        return this.file_urls !== undefined &&
          this.file_urls !== null &&
          this.file_urls.length > 0
          ? this.file_urls[0]
          : "";
      case "rating":
        return this.rating !== undefined && this.rating !== null
          ? Math.round(this.rating).toString()
          : "";
      case "yes_or_no":
        return this.answer_yes !== null ? this.answer_yes : "";
      case "number":
        return this.number !== undefined && this.number !== null
          ? this.number.toString()
          : "";
      case "date":
        return this.date !== undefined && this.date !== null
          ? dateFormat(this.date.toDate(), "dd/mm/yyyy")
          : "";
      default:
        return "";
    }
  }

  getEndDateFormat(seconds: boolean = false) {
    if (!this.end_date) return "";
    if (seconds) {
      return dateFormat(this.end_date.toDate(), "dd/mm/yyyy HH:MM:ss");
    } else {
      return dateFormat(this.end_date.toDate(), "dd/mm/yyyy HH:MM");
    }
  }

  getAuditorLabel(stores: Store[]) {
    if (this.store) {
      let store = stores!.find((store) => {
        return store.ref!.id === this.store!.id;
      });

      if (store) {
        return `${this.getEndDateFormat()} - ${store.name}`;
      }
    }
    return this.getEndDateFormat();
  }
}
