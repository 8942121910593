import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import LoginView from "../views/LoginView.vue";
import HomeView from "../views/HomeView.vue";
import AccountsView from "../views/AccountsView.vue";
import AccountView from "../views/AccountView.vue";
import StoresView from "../views/StoresView.vue";
import StoreView from "../views/StoreView.vue";
import DistributorsView from "../views/DistributorsView.vue";
import DistributorView from "../views/DistributorView.vue";
import BillingView from "../views/BillingView.vue";
import SupportView from "../views/SupportView.vue";
import ProbesView from "../views/probes/ProbesView.vue";
import DatabasesView from "../views/databases/DatabasesView.vue";
import DatabaseView from "../views/databases/DatabaseView.vue";
import TaskView from "../views/tasks/TaskView.vue";
import DatabaseElementView from "../views/elements/DatabaseElementView.vue";
import DatabaseTaskConfigurationView from "../views/tasks/DatabaseTaskConfigurationView.vue";
import StoreDatabaseView from "../views/databases/StoreDatabaseView.vue";
import StoreElementView from "../views/elements/StoreElementView.vue";
import LoadingView from "@/views/LoadingPage.vue";
import StoreTaskConfigurationView from "@/views/tasks/StoreTaskConfigurationView.vue";
import HistoryView from "@/views/tasks/HistoryView.vue";
import { getState } from "@/pinia/AppState";
import DocumentsView from "@/views/DocumentsView.vue";
import AuditingView from "@/views/auditing/AuditingView.vue";
import AuditingConfigurationView from "@/views/auditing/AuditingConfigurationView.vue";
import AuditingResultsView from "@/views/auditing/AuditingResultsView.vue";
import AllergensView from "@/views/allergens/AllergensView.vue";
import ProbeView from "@/views/probes/ProbeView.vue";
import CommunicationsView from "@/views/communications/CommunicationsView.vue";
import CommunicationView from "@/views/communications/CommunicationView.vue";
import CommunicationStatsView from "@/views/communications/CommunicationStatsView.vue";
import ChangePasswordView from "@/views/ChangePasswordView.vue";
import ShopView from "@/views/shop/ShopView.vue";
import BulkDatabaseElementView from "@/views/elements/BulkDatabaseElementView.vue";
import BulkDatabaseTaskView from "@/views/tasks/BulkDatabaseTaskView.vue";
import BulkStoreElementView from "@/views/elements/BulkStoreElementView.vue";
import BulkStoreTaskView from "@/views/tasks/BulkStoreTaskView.vue";
import TrainingsView from "@/views/training/TrainingsView.vue";
import TrainingView from "@/views/training/TrainingView.vue";
import TrainingStatsView from "@/views/training/TrainingStatsView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/trainingStats",
    name: "trainingStats",
    component: TrainingStatsView,
  },
  {
    path: "/training",
    name: "training",
    component: TrainingView,
  },
  {
    path: "/trainings",
    name: "trainings",
    component: TrainingsView,
  },
  {
    path: "/bulkDatabaseTask",
    name: "bulkDatabaseTask",
    component: BulkDatabaseTaskView,
  },
  {
    path: "/bulkStoreTask",
    name: "bulkStoreTask",
    component: BulkStoreTaskView,
  },
  {
    path: "/bulkDatabaseElement",
    name: "bulkDatabaseElement",
    component: BulkDatabaseElementView,
  },
  {
    path: "/bulkStoreElement",
    name: "bulkStoreElement",
    component: BulkStoreElementView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/shop",
    name: "shop",
    component: ShopView,
  },
  {
    path: "/databases",
    name: "databases",
    component: DatabasesView,
  },
  {
    path: "/storeDatabase",
    name: "storeDatabase",
    component: StoreDatabaseView,
  },
  {
    path: "/database",
    name: "database",
    component: DatabaseView,
  },
  {
    path: "/taskConf",
    name: "taskConf",
    component: DatabaseTaskConfigurationView,
  },
  {
    path: "/storeTaskConf",
    name: "storeTaskConf",
    component: StoreTaskConfigurationView,
  },
  {
    path: "/databaseElement",
    name: "databaseElement",
    component: DatabaseElementView,
  },
  {
    path: "/communications",
    name: "communications",
    component: CommunicationsView,
  },
  {
    path: "/communication",
    name: "communication",
    component: CommunicationView,
  },
  {
    path: "/communicationStats",
    name: "communicationStats",
    component: CommunicationStatsView,
  },
  {
    path: "/storeElement",
    name: "storeElement",
    component: StoreElementView,
  },
  {
    path: "/history",
    name: "history",
    component: HistoryView,
  },

  {
    path: "/task",
    name: "task",
    component: TaskView,
  },
  {
    path: "/probes",
    name: "probes",
    component: ProbesView,
  },
  {
    path: "/probe",
    name: "probe",
    component: ProbeView,
  },
  {
    path: "/support",
    name: "support",
    component: SupportView,
  },
  {
    path: "/billing",
    name: "billing",
    component: BillingView,
  },
  {
    path: "/distributors",
    name: "distributors",
    component: DistributorsView,
  },
  {
    path: "/distributor",
    name: "distributor",
    component: DistributorView,
  },
  {
    path: "/stores",
    name: "stores",
    component: StoresView,
  },

  {
    path: "/store",
    name: "store",
    component: StoreView,
  },
  {
    path: "/accounts",
    name: "accounts",
    component: AccountsView,
  },
  {
    path: "/account",
    name: "account",
    component: AccountView,
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/documents",
    name: "documents",
    component: DocumentsView,
  },
  {
    path: "/",
    name: "step",
    component: LoadingView,
  },
  {
    path: "/auditing",
    name: "auditing",
    component: AuditingView,
  },
  {
    path: "/auditingConfiguration",
    name: "auditingConfiguration",
    component: AuditingConfigurationView,
  },
  {
    path: "/auditingResults",
    name: "auditingResults",
    component: AuditingResultsView,
  },
  {
    path: "/allergens",
    name: "allergens",
    component: AllergensView,
  },
  {
    path: "/changePassword",
    name: "changePassword",
    component: ChangePasswordView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

let pending = true;
router.beforeEach((to, from, next) => {
  let state = getState();

  if (to.name == "step") {
    to.name = "home";
  }
  if (pending) {
    let token = to.query.token;
    let page = to.query.page;
    let storeId = to.query.store_id;
    let email = to.query.email;

    if (token != undefined) {
      state.token = token!.toString();
    }
    if (storeId != undefined) {
      state.customStoreId = storeId!.toString();
    }

    if (page != undefined) {
      state.initialPage = page!.toString();
    }

    if (email != undefined) {
      state.initialEmail = email!.toString();
    }

    next("/");

    pending = false;
  } else {
    next();
  }
});

export default router;
