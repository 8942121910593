
import { defineComponent, toRef, nextTick } from "vue";

export default defineComponent({
  expose: ["getValue", "setValue", "switch"],
  emits: ["update:modelValue"],
  name: "SeInput",
  data() {
    return {
      editable: null as Boolean | null,
      inputWidth: 100, // Add a property to store the input width
    };
  },
  props: {
    modelValue: {},
    value: {},
    edit: Boolean,
    auto: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    onClick() {
      if (this.auto) {
        this.switch();
      }
    },
    switch() {
      this.editable = !this.editable;

      if (this.editable) {
        nextTick(() => {
          this.calculateWidth(); // Calculate width when switching to editable mode
        });
      }
    },
    calculateWidth() {
      const textSpan = this.$refs.textSpan as HTMLElement;
      if (textSpan) {
        const computedStyle = window.getComputedStyle(textSpan);
        const width = textSpan.offsetWidth;
        const padding =
          parseFloat(computedStyle.paddingLeft) +
          parseFloat(computedStyle.paddingRight);
        this.inputWidth = width + padding; // Set inputWidth with textSpan's width

        if (this.inputWidth < 30) {
          this.inputWidth = 100;
        }
      }
    },
    getValue(): any {
      let val = this.$el.childNodes[0].value;

      if (val.trim() == "" && this.numberOnly) {
        return 0;
      } else {
        return val;
      }
    },
    setValue(value: string): void {
      this.$el.childNodes[0].value = value;
    },
    onInput($event: any) {
      var value = $event.target.value;
      this.$emit("update:modelValue", value);
    },
  },
  mounted() {
    this.calculateWidth(); // Calculate width on component mount
  },
});
